import Keycloak from "keycloak-js";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import en from "antd/es/locale/en_GB";
import nl from "antd/es/locale/nl_NL";
import { createBrowserHistory } from "history";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Router } from "react-router";
import { Switch } from "react-router-dom";
import { Translations } from "./components/administration/Translations";
import { CmsLayout } from "./components/CmsLayout";
import { Forbidden } from "./components/common/error/Forbidden";
import { NotFound } from "./components/common/error/NotFound";
import { ServerError } from "./components/common/error/ServerError";
import { PrivateRoute } from "./components/common/PrivateRoute";
import { DocumentEdit } from "./components/data/documents/DocumentEdit";
import { DocumentsList } from "./components/data/documents/DocumentsList";
import { DocumentWizard } from "./components/data/documents/DocumentWizard";
import { Home } from "./components/Home";
import { CountryDetails } from "./components/reference_lists/countries/CountryDetails";
import { CountryList } from "./components/reference_lists/countries/CountryList";
import { CurrencyDetails } from "./components/reference_lists/currencies/CurrencyDetails";
import { CurrencyList } from "./components/reference_lists/currencies/CurrencyList";
import { DocumentTypeDetails } from "./components/reference_lists/documenttypes/DocumentTypeDetails";
import { DocumentTypeList } from "./components/reference_lists/documenttypes/DocumentTypeList";
import { LanguageDetails } from "./components/reference_lists/languages/LanguageDetails";
import { LanguageList } from "./components/reference_lists/languages/LanguageList";
import { MaterialTypeDetails } from "./components/reference_lists/materialtypes/MaterialTypeDetails";
import { MaterialTypeList } from "./components/reference_lists/materialtypes/MaterialTypeList";
import { OrganizationDetails } from "./components/reference_lists/organizations/OrganizationDetails";
import { OrganizationList } from "./components/reference_lists/organizations/OrganizationList";
import { RegionDetails } from "./components/reference_lists/regions/RegionDetails";
import { RegionList } from "./components/reference_lists/regions/RegionList";
import { AttributeDetails } from "./components/templates/attributes/AttributeDetails";
import { AttributeList } from "./components/templates/attributes/AttributesList";
import { DocumentTemplateDetails } from "./components/templates/document_templates/DocumentTemplateDetails";
import { DocumentTemplateList } from "./components/templates/document_templates/DocumentTemplateList";
import { FieldDetails } from "./components/templates/fields/FieldDetails";
import { FieldList } from "./components/templates/fields/FieldList";
import { SecurityFeatureDetails } from "./components/templates/security_features/SecurityFeatureDetails";
import { SecurityFeatureList } from "./components/templates/security_features/SecurityFeaturesList";
import { DndContext } from "./context/app/DndContext";
import "./styles/antd.css";
import "./styles/App.css";
import Config from "./Config";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { CmsPageLoader } from "./components/common/PageComponents";
import AuthenticatedContext from "./context/app/AuthenticatedContext";
import { ImageUpload } from "./components/administration/ImageUpload";
import { MagazineExport } from "./components/administration/MagazineExport";
import { DocumentStatistics } from "./components/administration/DocumentStatistics";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChangeTracker } from "./components/administration/ChangesTracker";
import { CmsContextProvider } from "./context/app/CmsContext";

export const App = () => {
  const { i18n, t } = useTranslation();
  const history = useMemo(() => createBrowserHistory({ basename: "/" }), []);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 10 * 60 * 60 * 1000, // 10 minutes
            cacheTime: 10 * 60 * 60 * 1000, // 10 minutes
            retry: false,
          },
        },
      }),
  );

  const getLocale = () => {
    switch (i18n.language) {
      case "en":
        return en;
      case "nl":
        return nl;
      default:
        return en;
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={
        new Keycloak({
          url: Config.idsUrl,
          realm: Config.realm,
          clientId: Config.appId,
        })
      }
      LoadingComponent={
        <CmsPageLoader
          loading={true}
          key={"keycloak-pageloader"}
          title={t("common:authenticating")}
          subTitle={t("common:pleaseHold")}
        />
      }
      initOptions={{
        response_type: "id_token token",
        scope: "openid profile email",
        onload: "login-required",
      }}
      onTokens={(tokens) => sessionStorage.setItem("token", tokens.token ?? "")}
    >
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={getLocale()}>
            <CmsContextProvider>
              <DndContext>
                <AuthenticatedContext />
                <CmsLayout>
                  <Switch>
                    <PrivateRoute path={["/", "/login"]} exact component={Home} />
                    <PrivateRoute
                      path="/attributes"
                      component={AttributeList}
                      section="attribute"
                    />
                    <PrivateRoute
                      path="/attribute/:id?"
                      component={AttributeDetails}
                      section="attribute"
                    />
                    <PrivateRoute path="/countries" component={CountryList} section="country" />
                    <PrivateRoute
                      path="/country/:id?"
                      component={CountryDetails}
                      section="country"
                    />
                    <PrivateRoute path="/currencies" component={CurrencyList} section="currency" />
                    <PrivateRoute
                      path="/currency/:id?"
                      component={CurrencyDetails}
                      section="currency"
                    />
                    <PrivateRoute
                      path="/document-types"
                      component={DocumentTypeList}
                      section="documentType"
                    />
                    <PrivateRoute
                      path="/document-type/:id?"
                      component={DocumentTypeDetails}
                      section="documentType"
                    />
                    <PrivateRoute
                      path="/document-templates"
                      component={DocumentTemplateList}
                      section="documentTemplate"
                    />
                    <PrivateRoute
                      path="/document-template/:id?"
                      component={DocumentTemplateDetails}
                      section="documentTemplate"
                    />
                    <PrivateRoute
                      path="/document-series"
                      exact
                      component={DocumentsList}
                      section="document"
                    />
                    <PrivateRoute
                      path="/document-series/:id?"
                      component={DocumentEdit}
                      section="document"
                    />
                    <PrivateRoute
                      path="/document-wizard"
                      component={DocumentWizard}
                      section="document"
                    />
                    <PrivateRoute path="/fields" component={FieldList} section="field" />
                    <PrivateRoute path="/field/:id?" component={FieldDetails} section="field" />
                    <PrivateRoute path="/languages" component={LanguageList} section="language" />
                    <PrivateRoute
                      path="/language/:id?"
                      component={LanguageDetails}
                      section="language"
                    />
                    <PrivateRoute
                      path="/material-types"
                      component={MaterialTypeList}
                      section="materialType"
                    />
                    <PrivateRoute
                      path="/material-type/:id?"
                      component={MaterialTypeDetails}
                      section="materialType"
                    />
                    <PrivateRoute
                      path="/organizations"
                      component={OrganizationList}
                      section="organization"
                    />
                    <PrivateRoute
                      path="/organization/:id?"
                      component={OrganizationDetails}
                      section="organization"
                    />
                    <PrivateRoute path="/regions" component={RegionList} section="region" />
                    <PrivateRoute path="/region/:id?" component={RegionDetails} section="region" />
                    <PrivateRoute
                      path="/security-features"
                      component={SecurityFeatureList}
                      section="securityFeature"
                    />
                    <PrivateRoute
                      path="/security-feature/:id?"
                      component={SecurityFeatureDetails}
                      section="securityFeature"
                    />
                    <PrivateRoute
                      path="/translations"
                      component={Translations}
                      section="administration"
                    />
                    <PrivateRoute
                      path="/image-upload"
                      component={ImageUpload}
                      section="administration"
                    />
                    <PrivateRoute
                      path="/magazine-export"
                      component={MagazineExport}
                      section="administration"
                    />
                    <PrivateRoute
                      path="/document-statistics"
                      component={DocumentStatistics}
                      section="administration"
                    />
                    <PrivateRoute
                      path="/changes-tracker"
                      component={ChangeTracker}
                      section="administration"
                    />
                    <Route path="/server-error" component={ServerError} />
                    <Route path="/not-found" component={NotFound} />
                    <Route path="/forbidden" component={Forbidden} />
                  </Switch>
                </CmsLayout>
              </DndContext>
            </CmsContextProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </Router>
    </ReactKeycloakProvider>
  );
};
