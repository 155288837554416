import { TFunction } from "i18next";
import { SecurityFeature } from "../../models/templates/SecurityFeature";
import { validateName, validateRegEx, validateRequired } from "../Validators";
import { Group } from "../../models/templates/Group";
import { TreeNodeNormal } from "antd/lib/tree/Tree";
import { getLocalizedValue } from "../MultilingualHelper";

export const ValidateSecurityFeature = (
  securityFeature: SecurityFeature,
  t: TFunction
) => {
  const errors: any = {};

  if (!securityFeature.name || !securityFeature.code) return errors;

  const nameResult =
    validateRequired(securityFeature.name, t("properties:name")) ||
    validateName(securityFeature.name, t("properties:name"));
  if (nameResult != null) {
    errors.name = nameResult;
  }

  const codeResult =
    validateRequired(securityFeature.code, t("properties:code")) ||
    validateRegEx(
      securityFeature.code,
      /^[A-Z0-9_]*$/,
      t("validations:code", { property: t("properties:code") })
    );
  if (codeResult != null) {
    errors.code = codeResult;
  }

  return errors;
};

export const CreateGroupTreeviewNodes = (
  groups: Group[],
  securityFeatures: SecurityFeature[]
): TreeNodeNormal[] => {
  return groups.map((group) => {
    const securityFeatureNodes = securityFeatures
      .filter((securityFeature) => securityFeature.groupId === group.id)
      .map((securityFeature) => {
        return {
          key: securityFeature.id,
          title: getLocalizedValue(securityFeature.caption),
          selectable: true,
          isLeaf: true,
          children: undefined,
        };
      }) as TreeNodeNormal[];

    return {
      key: group.id as string,
      title: group.name,
      selectable: false,
      isLeaf: false,
      children: CreateGroupTreeviewNodes(
        group.children as Group[],
        securityFeatures
      ).concat(securityFeatureNodes),
    };
  });
};
