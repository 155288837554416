import { useTranslation } from "react-i18next";
import {
  CmsContent,
  CmsPageHeader,
  CmsPageLoader,
  CmsTable,
} from "../common/PageComponents";
import { Col, DatePicker, Layout, Radio, Row, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { CmsButton } from "../common/ButtonComponents";
import { RadioBox } from "../common/RadioBox";
import { FiltersBox } from "../common/FiltersBox";
import { useApi } from "../../hooks/useApi";
import { Country } from "../../models/reference_lists/Country";
import { DocumentTemplate } from "../../models/templates/DocumentTemplate";
import { Region } from "../../models/reference_lists/Region";
import { getLocalizedValue } from "../../utilities/MultilingualHelper";
import { Province } from "../../models/reference_lists/Province";
import { DocumentType } from "../../models/reference_lists/DocumentType";
import { MaterialType } from "../../models/reference_lists/MaterialType";
import parser from "lucene-query-parser";
import dayjs from "dayjs";
import {
  FilterOption,
  SearchSetting,
  emptyFilters,
  emtpyDateFilter,
  emtpySearchSetting,
  filterOptionKey,
  formLayout,
  getAttributeCaption,
  getAttributeDataList,
  getDocumentStatisticsConfig,
  getQueryByKey,
} from "../../utilities/configs/DocumentStatisticsConfig";
import { CmsColLayout, CmsForm, CmsFormItem } from "../common/FormComponents";
import Search from "antd/es/input/Search";
import Title from "antd/es/typography/Title";
import { CmsSearchSettingsModal } from "./CmsSearchSettingsModal";
import { Attribute } from "../../models/templates/Attribute";
import { capitalizeFirstLetter } from "../../utilities/StringHelper";
import { useNotification } from "../../hooks/useNotification";
import { ERROR_CODES } from "../../utilities/Constants";

type IndexColumn = {
  title: string;
  dataIndex: string;
  render?: (text: any) => string;
};

export const DocumentStatistics = () => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotification();
  const fixedColumns: IndexColumn[] = useMemo(
    () => [
      { title: t("properties:code"), dataIndex: "code" },
      { title: t("entities:documentType"), dataIndex: "documentType" },
    ],
    [t]
  );

  const [error, setError] = useState<string | null>(null);
  const [exportOptions, setExportOptions] = useState<string>("count");
  const [searchSetting, setSearchSetting] = useState<{
    modal: boolean;
    data?: SearchSetting;
  }>(emtpySearchSetting);
  const [columns, setColumns] = useState<IndexColumn[]>([]);
  const [fields, setFields] = useState<string[]>([]);
  const [inputType, setInputType] = useState<string>("interface");
  const [dateFilter, setDateFilter] = useState<{
    start?: string;
    end?: string;
  }>(emtpyDateFilter);
  const [searchResultsData, setSearchResultsData] = useState<any>([]);

  const [filters, setFilters] = useState<{
    product?: string;
    region?: string;
    country?: string;
    state?: string;
    documentType?: string;
    circulationStatus?: string;
    primaryPhotoTechnique?: string;
    textualPersonalisation?: string;
    seriesYear?: string;
    materialType?: string;
    hasManipulatedImages?: string;
    acquisitionSource?: string;
    collectorsItem?: string;
    commemorativeNote?: string;
    images?: string;
    securityFeatures?: string;
    startDate?: Date;
    endDate?: Date;
    documentStatus?: string;
    inVault?: string;
  }>(emptyFilters);

  const {
    getAll: getDocumentTemplates,
    data: documentTemplates,
    isLoading: isLoadingDocumentTemplate,
  } = useApi<DocumentTemplate>("documentTemplates");
  const {
    getAll: getCountries,
    data: countries,
    isLoading: isLoadingCountries,
  } = useApi<Country>("countries");
  const {
    getAll: getRegions,
    data: regions,
    isLoading: isLoadingRegions,
  } = useApi<Region>("regions");
  const {
    getAll: getProvinces,
    data: provinces,
    isLoading: isLoadingProvinces,
  } = useApi<Province>("provinces");
  const {
    getAll: getDocumentTypes,
    data: documentTypes,
    isLoading: isLoadingDocumentTypes,
  } = useApi<DocumentType>("documentTypes");
  const {
    getAll: getMaterialTypes,
    data: materialTypes,
    isLoading: isLoadingMaterialTypes,
  } = useApi<MaterialType>("materialTypes");
  const { post: postSearchResults, isLoading: isLoadingSearch } =
    useApi<any>(`search`);
  const {
    getAll: getSearchSettings,
    post: postSettings,
    data: searchSettings,
    isLoading: isLoadingSearchSettings,
  } = useApi<SearchSetting>(`search`);
  const {
    getAll: getAttributes,
    data: attributes,
    isLoading: isLoadingAttributes,
  } = useApi<Attribute>("attributes");

  useEffect(() => {
    (async () => {
      await getDocumentTemplates();
      await getCountries();
      await getRegions();
      await getProvinces();
      await getDocumentTypes();
      await getMaterialTypes();
      await getAttributes();
      await getSearchSettings({ path: "settings" });
    })();
  }, []);

  const isLoading =
    isLoadingCountries ||
    isLoadingDocumentTemplate ||
    isLoadingRegions ||
    isLoadingProvinces ||
    isLoadingDocumentTypes ||
    isLoadingMaterialTypes ||
    isLoadingAttributes ||
    isLoadingSearchSettings;

  const filtersActive = Object.values(filters).some(
    (value) => value !== undefined
  );

  useEffect(() => {
    if (searchResultsData) {
      const fields: string[] = [];
      const fixedFields = fixedColumns.map((column) => column.dataIndex);

      searchResultsData.forEach((result) => {
        if (result) {
          for (const field in result) {
            if (
              field &&
              !fields.includes(field) &&
              !fixedFields.includes(field)
            ) {
              fields.push(field);
            }
          }
        }
      });

      setFields(fields);
    }
  }, [fixedColumns, searchResultsData]);

  const constructQuery = (): string => {
    const query = [] as string[];

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined) {
        query.push(
          getQueryByKey(
            key as filterOptionKey,
            getDocumentStatisticsConfig(key as filterOptionKey).type === "list"
              ? (value as string).split("-").join("")
              : value
          )
        );
      }
    });

    if (Object.values(dateFilter).some((item) => item !== undefined)) {
      const start = dateFilter.start
        ? (dateFilter.start as string).split("-").join("")
        : "*";
      const end = dateFilter.end
        ? (dateFilter.end as string).split("-").join("")
        : null;

      query.push(`publishingDate:[${start} TO ${end}]`);
    }

    return query.join(" AND ");
  };

  const saveConfig = async (settings: SearchSetting) => {
    const response = await postSettings(settings, { path: "setting" });
    if (response.isSuccess) {
      notifySuccess(
        t("common:successSave", {
          entity: "search setting",
        })
      );
    }
  };

  const handleColumnChange = (values: string[]) => {
    const columns = [] as Array<IndexColumn>;

    values.forEach((value: any) => {
      columns.push({
        title: `${value}`,
        dataIndex: `${value}`,
      });
    });

    setColumns(columns);
  };

  const mergeDocumentTemplate = (): FilterOption[] => {
    const values = [] as string[];
    const returnValue = [] as FilterOption[];

    documentTemplates.forEach((template) => {
      if (template.name?.toLocaleLowerCase().includes("sided")) {
        values.push(template.id ?? "");
      } else {
        returnValue.push({
          name: template.name,
          value: template.id,
        });
      }
    });

    return values.length === 2
      ? returnValue.concat({
          name: `Documents`,
          value: `(${values[0]} OR ${values[1]})`,
        })
      : documentTemplates.map((template) => ({
          name: template.name,
          value: template.id,
        }));
  };

  return (
    <>
      <CmsPageHeader
        title={t("common:documentStatistics")}
        extra={
          <>
            <Radio.Group
              value={inputType}
              onChange={(e) => setInputType(e.target.value)}
            >
              <Radio.Button value={"interface"}>
                {t("common:interface")}
              </Radio.Button>
              <Radio.Button value={"manual"}>
                {t("common:advanced")}
              </Radio.Button>
            </Radio.Group>
          </>
        }
      />
      <Layout>
        <CmsContent>
          <Row>
            <Col flex={6}>
              <CmsPageLoader loading={isLoading}>
                {inputType === "interface" && (
                  <>
                    <FiltersBox
                      title="Filter options"
                      onChange={(key, value) => {
                        const config = getDocumentStatisticsConfig(
                          key as filterOptionKey
                        );

                        if (config) {
                          if (
                            columns.every(
                              (column) => column.dataIndex !== config.indexKey
                            )
                          ) {
                            setColumns(
                              columns.concat({
                                title: key,
                                dataIndex: config.indexKey,
                              })
                            );
                          }

                          setFilters((prevState) => ({
                            ...prevState,
                            [key]: value,
                          }));
                        }
                      }}
                      data={[
                        {
                          key: "product",
                          data: mergeDocumentTemplate(),
                          name: t("entities:product"),
                          defaultValue: filters.product,
                        },
                        {
                          key: "country",
                          data: countries.map((country) => ({
                            name: getLocalizedValue(country.name),
                            value: country.id,
                          })),
                          name: t("entities:country"),
                          defaultValue: filters.country,
                        },
                        {
                          key: "region",
                          data: regions.map((region) => ({
                            name: getLocalizedValue(region.name),
                            value: region.id,
                          })),
                          name: t("entities:productionRegion"),
                          defaultValue: filters.region,
                        },
                        {
                          key: "state",
                          data: provinces.map((province) => ({
                            name: province.name,
                            value: province.id,
                          })),
                          name: t("entities:province"),
                          defaultValue: filters.state,
                        },
                        {
                          key: "documentType",
                          data: documentTypes.map((documentType) => ({
                            name: documentType.name,
                            value: documentType.id,
                          })),
                          name: t("entities:documentType"),
                          defaultValue: filters.documentType,
                        },
                        {
                          key: "circulationStatus",
                          name: t("entities:circulationStatus"),
                          defaultValue: filters.circulationStatus,
                        },
                        {
                          key: "primaryPhotoTechnique",
                          name: getAttributeCaption(
                            attributes,
                            "Primary photo technique"
                          ),
                          defaultValue: filters.primaryPhotoTechnique,
                          data: getAttributeDataList(
                            attributes,
                            "Primary photo technique"
                          ),
                        },
                        {
                          key: "textualPersonalisation",
                          name: getAttributeCaption(
                            attributes,
                            "Textual personalisation"
                          ),
                          defaultValue: filters.textualPersonalisation,
                          data: getAttributeDataList(
                            attributes,
                            "Textual personalisation"
                          ),
                        },
                        {
                          key: "seriesYear",
                          name: capitalizeFirstLetter(
                            getAttributeCaption(attributes, "Series year")
                          ),
                          defaultValue: filters.seriesYear,
                        },
                        {
                          key: "materialType",
                          data: materialTypes.map((materialType) => ({
                            name: materialType.name,
                            value: materialType.id,
                          })),
                          name: t("entities:materialType"),
                          defaultValue: filters.materialType,
                        },
                        {
                          key: "hasManipulatedImages",
                          name: capitalizeFirstLetter(
                            getAttributeCaption(
                              attributes,
                              "Has manipulated images"
                            )
                          ),
                          defaultValue: filters.hasManipulatedImages,
                        },
                        {
                          key: "acquisitionSource",
                          name: t("properties:acquisitionSource"),
                          defaultValue: filters.acquisitionSource,
                        },
                        {
                          key: "collectorsItem",
                          name: capitalizeFirstLetter(
                            getAttributeCaption(attributes, "Collectors item")
                          ),
                          defaultValue: filters.collectorsItem,
                        },
                        {
                          key: "commemorativeNote",
                          name: capitalizeFirstLetter(
                            getAttributeCaption(
                              attributes,
                              "Commemorative note"
                            )
                          ),
                          defaultValue: filters.commemorativeNote,
                        },
                        {
                          key: "documentStatus",
                          name: t("properties:status"),
                          defaultValue: filters.documentStatus,
                        },
                        {
                          key: "inVault",
                          name: t("properties:inVault"),
                          defaultValue: filters.inVault,
                        },
                        {
                          key: "worldRegion",
                          data: regions.map((region) => ({
                            name: getLocalizedValue(region.name),
                            value: region.id,
                          })),
                          name: t("entities:worldRegion"),
                          defaultValue: filters.region,
                        },
                      ]}
                    />
                    <CmsPageLoader
                      loading={false}
                      subTitle={t("common:loadingData")}
                    >
                      <Row>
                        <Col {...CmsColLayout}>
                          <CmsForm {...formLayout}>
                            <CmsFormItem
                              key={"date-range"}
                              label={t("entities:dateRange")}
                              labelAlign={"right"}
                            >
                              <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: "50%" }}
                                defaultValue={
                                  dateFilter?.start
                                    ? dayjs(dateFilter.start, "YYYY-MM-DD")
                                    : undefined
                                }
                                onChange={(_, value) =>
                                  setDateFilter((prevState) => ({
                                    ...prevState,
                                    start: value !== "" ? value : undefined,
                                  }))
                                }
                              />
                              <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: "50%" }}
                                defaultValue={
                                  dateFilter?.end
                                    ? dayjs(dateFilter.end, "YYYY-MM-DD")
                                    : undefined
                                }
                                onChange={(_, value) =>
                                  setDateFilter((prevState) => ({
                                    ...prevState,
                                    end: value !== "" ? value : undefined,
                                  }))
                                }
                              />
                            </CmsFormItem>
                          </CmsForm>
                        </Col>
                        <Col {...CmsColLayout}>
                          <CmsForm {...formLayout}>
                            <CmsFormItem
                              key={"select-all-security-feature-counts"}
                              label={t("entities:allSecurityFeatureCounts")}
                              labelAlign={"right"}
                            >
                              <CmsButton
                                buttonType="add"
                                disabled={searchResultsData.length === 0}
                                onClick={() => {
                                  const additions = [] as IndexColumn[];
                                  fields.forEach((field) => {
                                    if (
                                      field.includes("securityfeaturecount") &&
                                      columns.every(
                                        (column) => column.dataIndex !== field
                                      )
                                    ) {
                                      additions.push({
                                        title: field,
                                        dataIndex: field,
                                      });
                                    }
                                  });

                                  setColumns(columns.concat(additions));
                                }}
                              />
                            </CmsFormItem>
                            <CmsFormItem
                              key={"select-all-field-actives"}
                              label={t("entities:allFieldsSelectionStatus")}
                              labelAlign={"right"}
                            >
                              <CmsButton
                                buttonType="add"
                                disabled={searchResultsData.length === 0}
                                onClick={() => {
                                  const additions = [] as IndexColumn[];
                                  fields.forEach((field) => {
                                    if (
                                      field.includes("fieldselectionstatus") &&
                                      columns.every(
                                        (column) => column.dataIndex !== field
                                      )
                                    ) {
                                      additions.push({
                                        title: field,
                                        dataIndex: field,
                                      });
                                    }
                                  });

                                  setColumns(columns.concat(additions));
                                }}
                              />
                            </CmsFormItem>
                          </CmsForm>
                        </Col>
                      </Row>
                    </CmsPageLoader>

                    <CmsButton
                      buttonType="send"
                      disabled={!filtersActive || isLoadingSearch}
                      onClick={async () => {
                        const result = await postSearchResults({
                          query: constructQuery(),
                        });

                        if (result.isSuccess) {
                          setSearchResultsData(result.data);
                        }
                      }}
                    />
                  </>
                )}
                {inputType === "manual" && (
                  <div style={{ paddingRight: "10px" }}>
                    <CmsFormItem label="" error={error}>
                      <Search
                        placeholder={t("common:searchQuery")}
                        onSearch={async (query) => {
                          if (!query) {
                            setError(t("validations:inputRequired"));
                            return;
                          }

                          try {
                            parser.parse(query);
                          } catch (e) {
                            const error = e as Error;
                            setError(error.message);
                            return;
                          }

                          const response = await postSearchResults({
                            query: query,
                          });

                          if (response.isSuccess) {
                            setSearchResultsData(response.data);
                          }

                          setError(null);
                        }}
                        enterButton
                        loading={isLoading}
                      />
                    </CmsFormItem>
                  </div>
                )}
              </CmsPageLoader>
            </Col>
            <Col flex={1}>
              <CmsPageLoader loading={isLoadingSearchSettings}>
                <RadioBox
                  title={t("common:exportOptions")}
                  defaultValue={exportOptions}
                  options={[
                    { value: "count", caption: t("common:exportOptionsCount") },
                    { value: "list", caption: t("common:exportOptionsList") },
                  ]}
                  onChange={(value) => setExportOptions(value)}
                />
                <CmsButton
                  buttonType="download"
                  disabled={
                    exportOptions === "count" ||
                    !filtersActive ||
                    isLoadingSearch
                  }
                  style={{ display: "block", marginTop: "10px" }}
                  onClick={async () => {
                    const response = await postSearchResults(
                      {
                        query: constructQuery(),
                        csvFields: fixedColumns
                          .concat(columns)
                          .map((column) => column.dataIndex),
                      },
                      {
                        contentType: "text/csv",
                      }
                    );

                    if (!response.isSuccess) {
                      notifyError(
                        ERROR_CODES.BAD_REQUEST,
                        "Something went wrong when downloading the csv file. Please try again later"
                      );
                      return;
                    }

                    const blob = new Blob(["\ufeff", response.data], {
                      type: "text/csv;charset=utf-8",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Search results.csv";
                    link.click();
                  }}
                />
                <div style={{ paddingTop: "10px" }}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder={t("common:exportOptionsSettingSelect")}
                    defaultValue={searchSetting.data?.id}
                    allowClear
                    onChange={async (id) => {
                      const data = searchSettings.find(
                        (setting) => setting.id === id
                      );

                      setSearchSetting((prevState) => ({
                        ...prevState,
                        data: data,
                      }));

                      if (data !== undefined) {
                        const parsedFields = JSON.parse(
                          data.fields
                        ) as IndexColumn[];
                        setFilters(JSON.parse(data.searchString));
                        setColumns(parsedFields);

                        if (fields.length === 0) {
                          setFields(
                            parsedFields.map((field) => field.dataIndex)
                          );
                        }

                        await getSearchSettings({ path: "settings" });
                      }
                    }}
                  >
                    {searchSettings.map((setting) => (
                      <Select.Option
                        key={`field_${setting.id}`}
                        value={setting.id}
                      >
                        {setting.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <CmsButton
                    buttonType="save"
                    disabled={!!searchSetting.data?.id}
                    onClick={async () => {
                      setSearchSetting((prevState) => ({
                        ...prevState,
                        modal: true,
                      }));
                    }}
                  >
                    Save
                  </CmsButton>
                  <CmsButton
                    buttonType="save"
                    disabled={!searchSetting.data?.id}
                    onClick={async () => {
                      if (searchSetting.data) {
                        await saveConfig({
                          id: searchSetting.data.id,
                          fields: JSON.stringify(columns),
                          name: searchSetting.data.name,
                          searchString: JSON.stringify(filters),
                        });
                        await getSearchSettings({ path: "settings" });
                      }
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Edit
                  </CmsButton>
                  <CmsButton
                    buttonType="default"
                    icon={<CloseOutlined />}
                    disabled={
                      Object.values(filters).every(
                        (item) => item === undefined
                      ) && columns.length === 0
                    }
                    danger
                    onClick={async () => {
                      setFilters(emptyFilters);
                      setColumns([]);
                      setSearchSetting(emtpySearchSetting);
                      setDateFilter(emtpyDateFilter);
                      await getSearchSettings({ path: "settings" });
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    reset
                  </CmsButton>
                </div>
              </CmsPageLoader>
            </Col>
          </Row>

          <CmsPageLoader loading={isLoading} style={{ paddingTop: "20px" }}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder={t("common:columns")}
              value={columns.map((col) => col.dataIndex)}
              defaultValue={columns.map((col) => col.dataIndex)}
              onChange={(values) => handleColumnChange(values)}
            >
              {fields.map((field, dataIndex) => (
                <Select.Option key={`field_${dataIndex}`} value={field}>
                  {field}
                </Select.Option>
              ))}
            </Select>
          </CmsPageLoader>

          {exportOptions === "count" && (
            <div style={{ paddingTop: "20px" }}>
              <Title>
                {t("common:documentSearchResultNumber", {
                  number: searchResultsData.length,
                })}
              </Title>
            </div>
          )}

          {exportOptions === "list" && (
            <div style={{ paddingTop: "20px" }}>
              <CmsTable
                loading={isLoadingSearch}
                dataSource={
                  searchResultsData.length > 0 ? searchResultsData : []
                }
                rowKey="id"
                columns={fixedColumns.concat(columns)}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    t("common:rangeOfItems", {
                      range: `${range[0]}-${range[1]}`,
                      total,
                    }),
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </div>
          )}
        </CmsContent>
        <CmsSearchSettingsModal
          name={searchSetting.data?.name}
          visible={searchSetting.modal}
          onSave={async (values) => {
            await saveConfig({
              fields: JSON.stringify(columns),
              name: values.name,
              searchString: JSON.stringify(filters),
            });
            await getSearchSettings({ path: "settings" });
            setSearchSetting((prevState) => ({
              ...prevState,
              modal: false,
            }));
          }}
          onClose={() =>
            setSearchSetting((prevState) => ({
              ...prevState,
              modal: false,
            }))
          }
        />
      </Layout>
    </>
  );
};
