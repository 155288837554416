import { Button, Input, Tabs } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { MultilingualInputData } from "../../models/MultilingualInputData";
import { Language } from "../../models/reference_lists/Language";
import { MultilingualCompareList } from "./MultilingualCompareList";
import styles from "./MultilingualInput.module.scss";
import { CmsTabs } from "./PageComponents";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { useFormikContext } from "formik";
import { DocumentSeries } from "../../models/data/Document";
import { useCmsContext } from "../../context/app/CmsContext";

const { TabPane } = Tabs;

interface Props {
  mlData: MultilingualInputData;
  readOnly?: boolean;
  disabled?: boolean;
  multiLine?: boolean;
  hideTabs?: boolean;
  onChange?(model: MultilingualInputData): void;
  compareList?: boolean;
  richEditor?: boolean;
  autoSave?: boolean;
}

interface OrderedLanguage {
  order: number;
  isoCode: string;
  name: string;
}

interface State {
  languages: OrderedLanguage[];
}

export const MultilingualInput = (props: Props) => {
  const context = useCmsContext();
  const [state, setState] = React.useState<State>({
    languages: [],
  });
  const tabPanePrefix = "multilingual_tabpane_";
  const { t } = useTranslation();
  const { data: languages, getAll: getLanguages } = useApi<Language>("languages");
  const { dirty, submitForm } = useFormikContext<DocumentSeries>();
  const editorRef = React.useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    const getView = localStorage.getItem("multilingualCompareListView");
    if (getView != null) {
      context?.setMultiLangualCompareView(JSON.parse(getView) === true);
    }
  }, []);

  useEffect(() => {
    getLanguages();
  }, [getLanguages]);

  useEffect(() => {
    const defaultOrderedLanguages = [
      { order: 1, isoCode: "nl" },
      { order: 2, isoCode: "en" },
      { order: 3, isoCode: "de" },
      { order: 4, isoCode: "fr" },
      { order: 5, isoCode: "it" },
      { order: 6, isoCode: "es" },
      { order: 7, isoCode: "ru" },
      { order: 8, isoCode: "zh" },
    ];

    let order = defaultOrderedLanguages.length + 1;

    const orderedLanguages = languages
      .map((language: Language) => {
        const matchingDefaultOrderedLanguage = defaultOrderedLanguages.find(
          (item) => item.isoCode === language.isoCode,
        );
        return {
          order: matchingDefaultOrderedLanguage ? matchingDefaultOrderedLanguage.order : order++,
          isoCode: language.isoCode as string,
          name: language.name as string,
        };
      })
      .sort((curr, next) => (curr.order > next.order ? 1 : -1));

    setState((prevState) => ({ ...prevState, languages: orderedLanguages }));
  }, [languages]);

  const updateMlDataItem = (key: string, value: string) => {
    if (props.onChange) {
      props.onChange({ ...props.mlData, [key]: value });
    }
  };

  const inputProperties = (isoCode: string) => ({
    disabled: props.disabled,
    readOnly: props.readOnly,
    minLength: 0,
    onChange: (e) => updateMlDataItem(isoCode, e.target.value),
    value: props.mlData && props.mlData[isoCode?.toLowerCase()],
    placeholder: t("properties:translation"),
  });

  const changeCompareView = (visible: boolean) => {
    context?.setMultiLangualCompareView(visible);
    localStorage.setItem("multilingualCompareListView", visible.toString());
  };

  const compareVisibleButton = () => {
    if (props.compareList !== undefined && props.compareList) {
      return (
        <Button
          type="default"
          key="new"
          icon={!context?.multiLangualCompareView ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          size="middle"
          title={t(
            `texts:${
              !context?.multiLangualCompareView ? "showCompareLanguage" : "hideCompareLanguage"
            }`,
          )}
          onClick={() => changeCompareView(!context?.multiLangualCompareView)}
        />
      );
    }
    return;
  };

  const compareVisibleList = () => {
    if (props.compareList !== undefined && props.compareList && context?.multiLangualCompareView) {
      return (
        <MultilingualCompareList
          mlData={props.mlData}
          languages={state.languages}
          translation={t}
        />
      );
    }
    return;
  };

  return (
    <>
      <div className={styles["multilingualInput"]}>
        <CmsTabs
          activeKey={`${tabPanePrefix}${context?.multiLangualInputActive}`}
          onChange={(key) => {
            if (props.autoSave && dirty) submitForm();
            context?.setMultiLangualInputActive(key.substring(tabPanePrefix.length));
          }}
          tabBarExtraContent={compareVisibleButton()}
          tabBarStyle={{ marginBottom: 0, borderBottom: 0, height: "30px" }}
          className={props.hideTabs ? styles["hidden-tabs"] : undefined}
        >
          {state.languages.map((language: OrderedLanguage) => (
            <TabPane
              key={`${tabPanePrefix}${language.isoCode.toLowerCase()}`}
              tab={language.isoCode.toUpperCase()}
            >
              {!props.multiLine ? (
                <>
                  <Input
                    key={`multilingual_input_${language.isoCode.toLowerCase()}`}
                    {...inputProperties(language.isoCode.toLowerCase())}
                  />
                  {compareVisibleList()}
                </>
              ) : (
                <>
                  {props.richEditor ? (
                    <Editor
                      key={`multilingual_input_textarea_${language.isoCode.toLowerCase()}`}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      init={{
                        height: 300,
                        width: "100%",
                        menubar: false,
                        paste_preprocess(_, args) {
                          args.content = args.content.replace(/font-family:(.*?)/g, "");
                        },
                        toolbar:
                          "bold italic underline strikethrough styleselect | numlist bullist | cut copy paste pasteword | search replace | undo redo | cleanup code | print | charmap removeformat visualchars | ",
                      }}
                      onEditorChange={(newValue, editor) =>
                        updateMlDataItem(language.isoCode.toLowerCase(), newValue)
                      }
                      disabled={props.disabled}
                      value={props.mlData && props.mlData[language.isoCode?.toLowerCase()]}
                    />
                  ) : (
                    <Input.TextArea
                      key={`multilingual_input_textarea_${language.isoCode.toLowerCase()}`}
                      rows={5}
                      {...inputProperties(language.isoCode.toLowerCase())}
                    />
                  )}
                  {compareVisibleList()}
                </>
              )}
            </TabPane>
          ))}
        </CmsTabs>
      </div>
    </>
  );
};
