import { CaretRightOutlined } from "@ant-design/icons";
import React from "react";
import "./DocumentTree.scss";
import { useCmsContext } from "../../../context/app/CmsContext";

export enum DocumentSection {
  PrimaryInfo,
  Instructions,
  AttributeValues,
  ViewDetails,
  ViewInstructions,
  ViewAttributeValues,
  ImageDetails,
  ImageInstructions,
}

interface Props {
  steps: Array<{
    name: string;
    section: DocumentSection;
    viewIndex?: number;
    imageIndex?: number;
  }>;
  currentStep: number;
  openStep(index: number): void;
}

export const DocumentTree = (props: Props) => {
  const context = useCmsContext();

  const openStep = (index: number) => {
    if (props.currentStep !== index) {
      if (
        !context?.unsavedChanges ||
        window.confirm("You have unsaved changes. Are you sure you want to leave this page?")
      ) {
        context?.setUnsavedChanges(false);
        props.openStep(index);
      }
    }
  };

  return (
    <React.Fragment>
      {props.steps.map((step, index) => {
        const isView = step.viewIndex !== undefined;
        const isImage = step.imageIndex !== undefined;
        return (
          <div key={index} className={`item ${isView ? "view" : ""} ${isImage ? "image" : ""}`}>
            {step.section === DocumentSection.ViewDetails ||
            step.section === DocumentSection.ImageDetails ? (
              <CaretRightOutlined />
            ) : null}
            <div
              key={index}
              className={`document-tree-item ${props.currentStep === index ? "selected" : ""}`}
              onClick={() => openStep(index)}
            >
              {step.name}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};
