import { Button, Col, Form, Row, Select } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import * as _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useApi } from "../../../hooks/useApi";
import { DocumentSeries } from "../../../models/data/Document";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import "../../../styles/docwizard.css";
import { CmsPageLoader } from "../../common/PageComponents";
import { DocumentSection, DocumentTree } from "./DocumentTree";
import { useCmsContext } from "../../../context/app/CmsContext";

export const DocumentWizard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: documentId } = useParams<{ id: string }>();
  const context = useCmsContext();
  const [state, setState] = useState<{
    document?: DocumentSeries;
    documentTemplate?: DocumentTemplate;
    steps: Array<{
      name: string;
      section: DocumentSection;
      viewIndex?: number;
      imageIndex?: number;
    }>;
    currentStep: number;
  }>({
    document: undefined,
    documentTemplate: undefined,
    steps: [
      { name: "Primary info", section: DocumentSection.PrimaryInfo },
      { name: "Attributes", section: DocumentSection.AttributeValues },
      { name: "Instructions", section: DocumentSection.Instructions },
    ],
    currentStep: 0,
  });

  const { get: getDocument, isLoading } = useApi<DocumentSeries>("documentSeries");

  const {
    data: documentTemplates,
    getAll: getDocumentTemplates,
    get: getDocumentTemplate,
  } = useApi<DocumentTemplate>("documentTemplates");

  const generateSteps = useCallback(
    (documentTemplate: DocumentTemplate): any[] => {
      const steps = state.steps;

      documentTemplate.viewTemplates.forEach((viewTemplate, viewIndex) => {
        steps.push({
          name: viewTemplate.name ?? "ViewTemplateName",
          section: DocumentSection.ViewDetails,
          viewIndex,
        });
        steps.push({
          name: "Instructions",
          section: DocumentSection.ViewInstructions,
          viewIndex,
        });
        steps.push({
          name: "Attribute Values",
          section: DocumentSection.ViewAttributeValues,
          viewIndex,
        });

        viewTemplate.imageTemplates.forEach((imageTemplate, imageIndex) => {
          steps.push({
            name: imageTemplate.name ?? "ImageTemplateName",
            section: DocumentSection.ImageDetails,
            viewIndex,
            imageIndex,
          });
          steps.push({
            name: "Instructions",
            section: DocumentSection.ImageInstructions,
            viewIndex,
            imageIndex,
          });
        });
      });

      return steps;
    },
    [state.steps],
  );

  useEffect(() => {
    (async () => {
      if (documentId) {
        const document = await getDocument(documentId);

        let documentTemplate;
        if (document.documentTemplateId) {
          documentTemplate = await getDocumentTemplate(document.documentTemplateId);
        }

        setState((prevState) => ({
          ...prevState,
          document,
          documentTemplate,
          steps: generateSteps(documentTemplate),
        }));
      }
      await getDocumentTemplates();
    })();
  }, [getDocumentTemplates, getDocumentTemplate, getDocument, documentId, generateSteps]);

  useEffect(() => {
    context?.setBreadcrumbItems([
      {
        key: "document",
        name: state.document?.instances?.[0].referenceName ?? "New document",
      },
    ]);
  }, [state.document]);

  const selectDocumentTemplate = async (documentTemplateId: string) => {
    /*if (!state.document || window.confirm('Are you sure you want to change the document template? All changes will be lost!')) {
      const documentTemplate = await getDocumentTemplate(documentTemplateId);
      const document = new DocumentSeries().withDocumentTemplate(documentTemplate);
      setState(prevState => ({ ...prevState, document, documentTemplate, steps: generateSteps(documentTemplate) }));
    }*/
  };

  /*const nextStep = (document: DocumentSeries) => {
    setState(prevState => ({...prevState, document, currentStep: state.currentStep !== state.steps.length - 1 ? state.currentStep + 1 : 0 });
  };

  const previousStep = () => {
    setState(prevState => ({...prevState, currentStep: state.currentStep - 1 });
  };*/

  const currentStep = state.steps[state.currentStep];

  return (
    <React.Fragment>
      <CmsPageLoader
        loading={!state.documentTemplate && isLoading}
        subTitle={t("common:loadingData")}
      >
        <PageHeader
          title="Document"
          style={{
            padding: "0 0 12px 0",
            margin: "0 0 12px 0",
            borderBottom: "1px solid rgb(235, 237, 240)",
          }}
          extra={[
            <Button
              key="cancel"
              onClick={() => {
                history.push(`/documents`);
              }}
            >
              Cancel
            </Button>,
            <Button key="save" type="primary">
              Save
            </Button>,
          ]}
        />
        <Row gutter={16}>
          <Col span={4}>
            {state.documentTemplate ? (
              <DocumentTree
                steps={state.steps}
                currentStep={state.currentStep}
                openStep={(index) =>
                  setState((prevState) => ({
                    ...prevState,
                    currentStep: index,
                  }))
                }
              />
            ) : null}
          </Col>
          <Col span={20}>
            {currentStep.section === DocumentSection.PrimaryInfo ? (
              <React.Fragment>
                <Form.Item label="Template" style={{ display: "flex", marginBottom: "16px" }}>
                  <Select
                    style={{ width: "350px" }}
                    placeholder="Select a document template"
                    value={state.document ? state.document.documentTemplateId : ""}
                    onChange={(documentTemplateId) => selectDocumentTemplate(documentTemplateId)}
                  >
                    {_.map(documentTemplates, (item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </React.Fragment>
            ) : null}
            <div style={{ minHeight: "800px" }}>
              {state.document && state.documentTemplate ? (
                <div key="content" style={{ margin: "0", padding: "15px 0px 0px 0px" }}>
                  {/*(() => {
                        switch (currentStep.section) {
                          case DocumentSection.PrimaryInfo:
                            <DocumentDetails
                              onSave={document => nextStep(document)}
                            />;
                          case DocumentSection.Instructions:
                            return <InstructionsList
                              key='document-instructions'
                              instructions={state.document.instructions}
                              onSave={instructions => {
                                const updatedDocument = update(state.document, { instructions: { $set: instructions } });
                                nextStep(updatedDocument);
                              }}
                              onBack={previousStep}
                            />;
                          case DocumentSection.AttributeValues:
                            <AttributeValueEntries
                              key='document-attributes'
                              attributeLinks={state.documentTemplate.attributeLinks}
                              onSave={attributeValues => {
                                const updatedDocument = update(state.document, { attributeValues: { $set: attributeValues } });
                                nextStep(updatedDocument);
                              }}
                              onBack={previousStep}
                            />;
                          case DocumentSection.ViewDetails:
                            return <ViewProperties
                              view={state.document.views[currentStep.viewIndex]}
                              onSave={view => {
                                const updatedDocument = update(state.document, { views: { [currentStep.viewIndex]: { $set: view } } });
                                nextStep(updatedDocument);
                              }}
                              onBack={previousStep}
                            />;
                          case DocumentSection.ViewInstructions:
                            return <InstructionsList
                              key={'view-instructions-' + state.document.views[currentStep.viewIndex].id}
                              instructions={state.document.views[currentStep.viewIndex].instructions}
                              onSave={instructions => {
                                const updatedDocument = update(state.document, { views: { [currentStep.viewIndex]: { instructions: { $set: instructions } } } });
                                nextStep(updatedDocument);
                              }}
                              onBack={previousStep}
                            />;
                          case DocumentSection.ViewAttributeValues:
                            <AttributeValueEntries
                              key='view-attributes'
                              attributeLinks={state.documentTemplate.viewTemplates[currentStep.viewIndex].attributeLinks}
                              onSave={attributeValues => {
                                const updatedDocument = update(state.document, { views: { [currentStep.viewIndex]: { attributeValues: { $set: attributeValues } } } });
                                nextStep(updatedDocument);
                              }}
                              onBack={previousStep}
                            />;
                          case DocumentSection.ImageDetails:
                            return <ImageDetails
                              image={state.document.views[currentStep.viewIndex].images[currentStep.imageIndex]}
                              imageTemplate={state.documentTemplate.viewTemplates[currentStep.viewIndex].imageTemplates[currentStep.imageIndex]}
                              onSave={image => {
                                const updatedDocument = update(state.document,
                                  { views: { [currentStep.viewIndex]: { images: { [currentStep.imageIndex]: { $set: image } } } } });

                                nextStep(updatedDocument);
                              }}
                              onBack={previousStep}
                            />;
                          case DocumentSection.ImageInstructions:
                            return <InstructionsList
                              key={'image-instructions-' + state.document.views[currentStep.viewIndex].images[currentStep.imageIndex].id}
                              instructions={state.document.views[currentStep.viewIndex].images[currentStep.imageIndex].instructions}
                              onSave={instructions => {
                                const updatedDocument = update(state.document,
                                  { views: { [currentStep.viewIndex]: { images: { [currentStep.imageIndex]: { instructions: { $set: instructions } } } } } });

                                nextStep(updatedDocument);
                              }}
                              onBack={previousStep}
                            />;
                        }
                      })()*/}
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </CmsPageLoader>
    </React.Fragment>
  );
};
