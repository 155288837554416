import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, TreeSelect } from "antd";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { default as config } from "../../../Config";
import { useApi } from "../../../hooks/useApi";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { MultilingualInputData } from "../../../models/MultilingualInputData";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { SecurityFeature } from "../../../models/templates/SecurityFeature";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import {
  addItem,
  OrderableItem,
  rearrangeItems,
  removeItem,
} from "../../../utilities/ListHelpers";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import {
  CmsBackButton,
  CmsDeleteButton,
  CmsSaveButton,
  CmsUploadButton,
} from "../../common/ButtonComponents";
import { DragSortTable } from "../../common/DragSortTable";
import { CmsForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { RowButtonSet } from "../../common/RowButtonSet";
import { AttributeLinkDetailsModal } from "../attributeLinks/AttributeLinkDetailsModal";
import { InstructionsList } from "../../data/documents/InstructionsList";
import { ValidateSecurityFeature } from "../../../utilities/helpers/security-features-helper";
import {
  useQuerySecurityFeature,
  useSaveSecurityFeature,
} from "../../../queries/security-features/detail";
import { useQuerySecurityFeatureGroups } from "../../../queries/security-features-groups/lists";
import { useCmsContext } from "../../../context/app/CmsContext";

export const SecurityFeatureDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("securityFeature");
  const { push } = useHistory();
  const { id: securityFeatureId } = useParams<Entity>();
  const context = useCmsContext();
  const [state, setState] = React.useState<{
    selectedAttributeLink: AttributeLink | null;
    selectedAttributeLinkIndex: number | null;
    attributeLinkModalVisible: boolean;
    selectedInstructionIndex: number | null;
    showInstructionSelection: boolean;
  }>({
    selectedAttributeLink: null,
    selectedAttributeLinkIndex: null,
    attributeLinkModalVisible: false,
    selectedInstructionIndex: null,
    showInstructionSelection: false,
  });

  const {
    data: securityFeature,
    isLoading: isLoadingSecurityFeature,
    refetch: refetchSecurityFeature,
  } = useQuerySecurityFeature(securityFeatureId);
  const {
    mutateAsync: saveSecurityFeature,
    isLoading: isLoadingSaveSecurityFeature,
  } = useSaveSecurityFeature();
  const { data: groups, isLoading: isLoadingSecurityFeatureGroups } =
    useQuerySecurityFeatureGroups();

  const { post: saveFile } = useApi<File>("file");

  useEffect(() => {
    if (securityFeature) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateSecurityFeature", {
          name: securityFeature.name,
          code: securityFeature.code,
        })
      );

      context?.setBreadcrumbItems([
        {
          key: "currency",
          name: securityFeature.name ?? t("common:new"),
        },
      ]);
    }
  }, [securityFeature, t]);

  const noDataAny = (!securityFeature && !!securityFeatureId) || !groups;

  const isLoadingAny =
    isLoadingSecurityFeatureGroups ||
    (isLoadingSecurityFeature && !!securityFeatureId) ||
    isLoadingSaveSecurityFeature;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={securityFeature ?? ({} as SecurityFeature)}
      enableReinitialize={true}
      validate={(securityFeature: SecurityFeature) =>
        ValidateSecurityFeature(securityFeature, t)
      }
      onSubmit={async (securityFeature) => {
        await saveSecurityFeature(securityFeature);
        !securityFeature.id
          ? push(`/security-features`)
          : await refetchSecurityFeature();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          setFieldValue,
          handleSubmit,
          values,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title={t("entities:securityFeature")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/security-features`)}
                />,
                !!canUpdate && (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ),
              ]}
            />

            <CmsForm>
              <Form.Item label={t("properties:group")} required={true}>
                <TreeSelect
                  style={{ width: "100%", marginTop: "5px" }}
                  placeholder={t("properties:group")}
                  disabled={!canUpdate || isLoadingAny}
                  treeDefaultExpandAll={true}
                  treeData={convertGroupsForTreeSelect(groups)}
                  value={values.groupId}
                  onChange={(e) => setFieldValue("groupId", e)}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:name")}
                validateStatus={!errors.name ? "success" : "error"}
                help={errors.name}
                required={true}
              >
                <Input
                  style={{ marginTop: "5px" }}
                  placeholder={t("properties:name")}
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  maxLength={50}
                  value={values.name}
                  id="name"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:caption")}
                validateStatus={!errors.caption ? "success" : "error"}
                help={errors.caption}
              >
                <MultilingualInput
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  mlData={values.caption}
                  onChange={(e) => setFieldValue("caption", e)}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:code")}
                validateStatus={!errors.code ? "success" : "error"}
                help={errors.code}
                required={true}
              >
                <Input
                  id="code"
                  placeholder={t("properties:code")}
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  maxLength={75}
                  key="3"
                  value={values.code}
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                    e.target.value = e.target.value.replace(" ", "_");
                    handleChange(e);
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t("properties:description")}
                validateStatus={!errors.description ? "success" : "error"}
                help={errors.description}
              >
                <MultilingualInput
                  multiLine={true}
                  disabled={isLoadingAny}
                  readOnly={!canUpdate}
                  mlData={values.description}
                  onChange={(e) => setFieldValue("description", e)}
                />
              </Form.Item>
              <Form.Item label={t("entities:attributes")}>
                <DragSortTable
                  bordered
                  size={"small"}
                  onRow={(record, index) =>
                    ({
                      moveRow: (fromIndex, toIndex) =>
                        setFieldValue(
                          "attributeLinks",
                          rearrangeItems(
                            values.attributeLinks as OrderableItem[],
                            fromIndex,
                            toIndex
                          )
                        ),
                    }) as any
                  }
                  columns={[
                    {
                      title: t("entities:attribute"),
                      dataIndex: "attributeName",
                    },
                    {
                      title: t("properties:caption"),
                      dataIndex: "caption",
                      render: (caption: MultilingualInputData) => {
                        if (caption) {
                          return <span>{getLocalizedValue(caption)}</span>;
                        } else {
                          return null;
                        }
                      },
                    },
                    {
                      title: t("properties:exportable"),
                      dataIndex: "exportable",
                      render: (value) =>
                        value ? t("common:yes") : t("common:no"),
                    },
                    {
                      title: t("properties:required"),
                      dataIndex: "isRequired",
                      render: (value) =>
                        value ? t("common:yes") : t("common:no"),
                    },
                    ...(!!canUpdate
                      ? [
                          {
                            title: (
                              <Button
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                disabled={isLoadingAny}
                                style={{ margin: "0px 2px" }}
                                size="small"
                                onClick={() => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    selectedAttributeLink: new AttributeLink(),
                                    selectedAttributeLinkIndex: -1,
                                    attributeLinkModalVisible: true,
                                  }));
                                }}
                              />
                            ),
                            dataIndex: "actions",
                            align: "right" as any,
                            render: (text: any, record: any, index) => (
                              <RowButtonSet
                                onEdit={() =>
                                  setState((prevState) => ({
                                    ...prevState,
                                    selectedAttributeLink: record,
                                    attributeLinkModalVisible: true,
                                    selectedAttributeLinkIndex: index,
                                  }))
                                }
                                onDelete={() => {
                                  const attributeLinks = removeItem(
                                    index,
                                    values.attributeLinks as OrderableItem[]
                                  );
                                  setFieldValue(
                                    "attributeLinks",
                                    attributeLinks
                                  );
                                  setState((prevState) => ({
                                    ...prevState,
                                    selectedAttributeLink: null,
                                    selectedAttributeLinkIndex: -1,
                                  }));
                                }}
                              />
                            ),
                          },
                        ]
                      : []),
                  ]}
                  dataSource={values.attributeLinks as OrderableItem[]}
                  rowKey={(row, index) => row.order || (index as any)}
                  pagination={false}
                />
              </Form.Item>
              <Form.Item label={t("properties:texts")}>
                <InstructionsList
                  key={"securityFeatureInstructionsList"}
                  instructionsList={values.instructions}
                  onChange={(instructions) =>
                    setFieldValue("instructions", instructions)
                  }
                />
              </Form.Item>
              <Form.Item label={t("properties:exampleImage")}>
                {values.fileId ? (
                  <div style={{ marginBottom: "10px" }}>
                    <img
                      src={`${config.apiUrl}file/${
                        values.fileId
                      }?timeStamp=${new Date().getTime()}`}
                      alt="example"
                      style={{ maxWidth: "400px", maxHeight: "400px" }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <CmsUploadButton
                    accept="image/*"
                    customRequest={async (options) => {
                      setState((prevState) => ({
                        ...prevState,
                        isImageLoading: true,
                      }));
                      const response = await saveFile(options.file, {
                        path: values.fileId,
                        postAsFile: true,
                      });
                      if (
                        response.isSuccess &&
                        response.data.id !== values.fileId
                      ) {
                        setFieldValue(`fileId`, response.data.id);
                      }
                      setState((prevState) => ({
                        ...prevState,
                        isImageLoading: false,
                      }));
                    }}
                  />
                  {values.fileId ? (
                    <CmsDeleteButton
                      style={{ marginLeft: "10px" }}
                      onConfirm={(e) => {
                        e?.stopPropagation();
                        setFieldValue(`fileId`, null);
                      }}
                    />
                  ) : null}
                </div>
              </Form.Item>
            </CmsForm>

            {state.attributeLinkModalVisible && (
              <AttributeLinkDetailsModal
                attributeLink={state.selectedAttributeLink as AttributeLink}
                onSave={(attributeLink) => {
                  let attributeLinks = values.attributeLinks;

                  if (!attributeLinks) return;

                  if (
                    state.selectedAttributeLinkIndex &&
                    state.selectedAttributeLinkIndex >= 0
                  ) {
                    attributeLinks[state.selectedAttributeLinkIndex] =
                      attributeLink;
                  } else {
                    attributeLinks = addItem(
                      attributeLink as OrderableItem,
                      attributeLinks as OrderableItem[]
                    ) as AttributeLink[];
                  }

                  setFieldValue("attributeLinks", attributeLinks);

                  setState((prevState) => ({
                    ...prevState,
                    selectedAttributeLink: null,
                    selectedAttributeLinkIndex: -1,
                    attributeLinkModalVisible: false,
                  }));
                }}
                onClose={() => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedAttributeLink: null,
                    selectedAttributeLinkIndex: -1,
                    attributeLinkModalVisible: false,
                  }));
                }}
              />
            )}
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
