import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { MaterialType } from "../../../models/reference_lists/MaterialType";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import {
  CmsForm,
  CmsMultilingualText,
  CmsText,
} from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import {
  useQueryMaterialType,
  useSaveMaterialType,
} from "../../../queries/material-types/detail";
import { ValidateMaterialType } from "../../../utilities/helpers/material-type-helper";
import { useCmsContext } from "../../../context/app/CmsContext";

export const MaterialTypeDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("materialType");
  const { push } = useHistory();
  const { id: materialTypeId } = useParams<Entity>();
  const context = useCmsContext();

  const {
    data: materialType,
    isLoading: isLoadingMaterialType,
    refetch: refetchMaterialType,
  } = useQueryMaterialType(materialTypeId);
  const { mutateAsync: saveMaterialType, isLoading: isSavingMaterialType } =
    useSaveMaterialType();

  React.useEffect(() => {
    if (materialType) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateMaterialType", {
          name: materialType.name,
        })
      );

      context?.setBreadcrumbItems([
        {
          key: "material-type",
          name: materialType.name ?? t("common:new"),
        },
      ]);
    }
  }, [materialType, t]);

  const isLoadingAny =
    (isLoadingMaterialType && !!materialTypeId) || isSavingMaterialType;
  const noDataAny = !materialType && !!materialTypeId;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={materialType ?? new MaterialType()}
      enableReinitialize={true}
      validate={(materialType: MaterialType) =>
        ValidateMaterialType(materialType, t)
      }
      onSubmit={async (materialType: MaterialType) => {
        await saveMaterialType(materialType);
        !materialType.id
          ? push(`/material-types`)
          : await refetchMaterialType();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title={t("entities:materialType")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/material-types`)}
                />,
                !!canUpdate ? (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ) : null,
              ]}
            />
            <CmsForm>
              <CmsText
                id="name"
                label={t("properties:name")}
                placeholder={t("properties:name")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={100}
                error={errors.name}
                value={values.name}
                onChange={handleChange}
              />

              <CmsMultilingualText
                label={t("properties:caption")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                error={errors.caption}
                value={values.caption}
                onChange={(value) => setFieldValue("caption", value)}
              />
            </CmsForm>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
