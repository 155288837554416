import {
  Button,
  Checkbox,
  Form,
  Layout,
  Modal,
  Select,
  TreeSelect,
} from "antd";
import { Formik } from "formik";
import * as _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/useApi";
import { Field } from "../../../models/templates/Field";
import { FieldLink } from "../../../models/templates/FieldLink";
import { Group } from "../../../models/templates/Group";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { validateRequired } from "../../../utilities/Validators";
import { CmsModalForm } from "../../common/FormComponents";
import { CmsPageLoader } from "../../common/PageComponents";

interface Props {
  visible: boolean;
  fieldLink: FieldLink;
  onClose(): void;
  onSave(fieldLink: FieldLink): void;
}

interface State {
  selectedGroupId?: string;
}

export const FieldLinkDetailsModal = (props: Props) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>({
    selectedGroupId: undefined,
  });

  const {
    data: fieldGroups,
    getAll: getFieldGroups,
    isLoading: isLoadingGroups,
  } = useApi<Group>("fieldGroups");
  const {
    data: fields,
    getAll: getFields,
    get: getField,
    isLoading: isLoadingFields,
  } = useApi<Field>("fields");

  const isLoading = isLoadingGroups || isLoadingFields;

  useEffect(() => {
    getFieldGroups();
  }, [getFieldGroups]);

  useEffect(() => {
    async function setGroup(fieldId) {
      const field = await getField(fieldId);
      const groupId = field.groupId;
      setState((prevState) => ({ ...prevState, selectedGroupId: groupId }));
    }

    if (props.fieldLink) {
      if (props.fieldLink.fieldId) {
        setGroup(props.fieldLink.fieldId);
      } else {
        setState((prevState) => ({ ...prevState, selectedGroupId: undefined }));
      }
    }
  }, [props.fieldLink, getField]);

  useEffect(() => {
    if (state.selectedGroupId) {
      getFields({ querystringParams: { groupId: state.selectedGroupId } });
    }
  }, [state.selectedGroupId, getFields]);

  const validate = (fieldLink: FieldLink) => {
    const errors: any = {};

    const fieldIdResult = validateRequired(
      fieldLink.fieldId,
      t("entities:field")
    );
    if (fieldIdResult != null) {
      errors.fieldId = fieldIdResult;
    }

    return errors;
  };

  if (props.fieldLink == null) {
    return null;
  } else {
    return (
      <Formik
        initialValues={props.fieldLink}
        enableReinitialize={true}
        validate={validate}
        onSubmit={(fieldLink) => {
          props.onSave(fieldLink);
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            isValid,
          } = formikProps;

          return (
            <Modal
              width={700}
              title={t("entities:field")}
              open={props.visible}
              footer={[
                <Button id="btnCancel" key="cancel" onClick={props.onClose}>
                  {t("common:cancel")}
                </Button>,
                <Button
                  id="btnSave"
                  key="save"
                  type="primary"
                  disabled={!isValid}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("common:save")}
                </Button>,
              ]}
              onCancel={props.onClose}
            >
              <CmsPageLoader
                loading={isLoading}
                subTitle={t("common:loadingData")}
              >
                <Layout style={{ background: "#fff" }}>
                  <div key="fieldLinkDetails">
                    <CmsModalForm>
                      <Form.Item
                        label={t("entities:field")}
                        validateStatus={!errors.fieldId ? "success" : "error"}
                        help={errors.fieldId}
                        required={true}
                      >
                        <React.Fragment>
                          <TreeSelect
                            id="groupId"
                            treeDefaultExpandAll={true}
                            style={{ width: "40%" }}
                            placeholder={t("properties:group")}
                            onChange={(groupId) => {
                              setFieldValue("groupId", groupId);
                              setFieldValue("fieldId", undefined);
                              setState((prevState) => ({
                                ...prevState,
                                selectedGroupId: groupId,
                              }));
                            }}
                            value={state.selectedGroupId}
                            treeData={convertGroupsForTreeSelect(fieldGroups)}
                           />
                          &nbsp;
                          <Select
                            showSearch
                            id="fieldId"
                            style={{ width: "58%" }}
                            placeholder={t("entities:field")}
                            filterOption={(input, option) =>
                              option?.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(fieldId: string) => {
                              const field = fields.find(
                                (field) => field.id === fieldId
                              );
                              if (field) {
                                setFieldValue("fieldName", field.name);
                              }
                              setFieldValue("fieldId", fieldId);
                            }}
                            value={values.fieldId}
                          >
                            {_.map(fields, (value, index) => (
                              <Select.Option key={`${index}`} value={value.id}>
                                {value.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </React.Fragment>
                      </Form.Item>
                      <Form.Item label={t("properties:exportable")}>
                        <Checkbox
                          id="exportable"
                          checked={values.exportable}
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <Form.Item label={t("properties:required")}>
                        <Checkbox
                          id="isRequired"
                          checked={values.isRequired}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </CmsModalForm>
                  </div>
                </Layout>
              </CmsPageLoader>
            </Modal>
          );
        }}
      </Formik>
    );
  }
};
