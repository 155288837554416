import { PlusOutlined } from "@ant-design/icons";
import { Button, Select, Table } from "antd";
import CheckboxGroup, { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import {
  Organization,
  OrganizationProductType,
  OrganizationType,
} from "../../../models/reference_lists/Organization";
import { Product } from "../../../models/reference_lists/Product";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import {
  CmsForm,
  CmsFormItem,
  CmsSelect,
  CmsText,
} from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { RowButtonSet } from "../../common/RowButtonSet";
import { ProductDetailsModal } from "./ProductDetailsModal";
import { useQueryCountries } from "../../../queries/countries/lists";
import {
  useQueryOrganization,
  useSaveOrganization,
} from "../../../queries/organizations/detail";
import {
  getTypeValues,
  setTypeValues,
  ValidateOrganization,
} from "../../../utilities/helpers/organization-helper";
import { useCmsContext } from "../../../context/app/CmsContext";

export const OrganizationDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("organization");
  const { push } = useHistory();
  const { id: organizationId } = useParams<Entity>();
  const context = useCmsContext();
  const [state, setState] = React.useState<{
    selectedProduct: Product | null;
    selectedProductIndex: number;
    modalVisible: boolean;
  }>({
    selectedProduct: null,
    selectedProductIndex: -1,
    modalVisible: false,
  });

  const { data: countries, isLoading: isLoadingCountries } =
    useQueryCountries();
  const {
    data: organization,
    isLoading: isLoadingOrganization,
    refetch: refetchOrganization,
  } = useQueryOrganization(organizationId);
  const { mutateAsync: saveOrganization, isLoading: isSavingOrganization } =
    useSaveOrganization();

  React.useEffect(() => {
    if (organization) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateOrganization", {
          name: organization.name,
        })
      );

      context?.setBreadcrumbItems([
        {
          key: "organization",
          name: organization.name ?? t("common:new"),
        },
      ]);
    }
  }, [organization, t]);

  const isLoadingAny =
    (isLoadingOrganization && !!organizationId) ||
    isLoadingCountries ||
    isSavingOrganization;
  const noDataAny = (!organization && !!organizationId) || !countries;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={organization ?? new Organization()}
      enableReinitialize={true}
      validate={(organization: Organization) =>
        ValidateOrganization(organization, t)
      }
      onSubmit={async (organization: Organization) => {
        await saveOrganization(organization);
        !organization.id ? push(`/organizations`) : await refetchOrganization();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title={t("entities:organization")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/organizations`)}
                />,
                !!canUpdate ? (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ) : (
                  false
                ),
              ]}
            />
            <CmsForm>
              <CmsFormItem
                label={t("properties:type")}
                error={errors.type}
                required={true}
              >
                <CheckboxGroup
                  options={[
                    {
                      label: t("properties:organizationTypeProducer"),
                      value: "PRODUCER",
                    },
                    {
                      label: t("properties:organizationTypeIssuer"),
                      value: "ISSUER",
                    },
                    {
                      label: t("properties:organizationTypeReference"),
                      value: "REFERENCE",
                    },
                  ]}
                  value={
                    getTypeValues(
                      values.type as OrganizationType
                    ) as CheckboxValueType[]
                  }
                  disabled={!canUpdate || isLoadingAny}
                  onChange={(value) =>
                    setFieldValue("type", setTypeValues(value))
                  }
                />
              </CmsFormItem>

              <CmsFormItem
                label={t("properties:productType")}
                error={errors.productType}
                required={true}
              >
                <CheckboxGroup
                  options={[
                    {
                      label: t("properties:productTypeDocument"),
                      value: "DOCUMENT",
                    },
                    {
                      label: t("properties:productTypeBanknote"),
                      value: "BANKNOTE",
                    },
                  ]}
                  value={
                    getTypeValues(
                      values.productType as OrganizationProductType
                    ) as CheckboxValueType[]
                  }
                  disabled={!canUpdate || isLoadingAny}
                  onChange={(value) =>
                    setFieldValue("productType", setTypeValues(value))
                  }
                />
              </CmsFormItem>

              <CmsText
                id="name"
                label={t("properties:name")}
                placeholder={t("properties:name")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={150}
                error={errors.name}
                value={values.name}
                onChange={handleChange}
              />

              <CmsSelect
                id="countryId"
                label={t("properties:country")}
                placeholder={t("properties:country")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                loading={isLoadingCountries}
                error={errors.countryId}
                value={values.countryId}
                onChange={(value) => setFieldValue("countryId", value)}
              >
                {countries.map((country) => (
                  <Select.Option key={country.id} value={country.id}>
                    {getLocalizedValue(country.name)}
                  </Select.Option>
                ))}
              </CmsSelect>

              <CmsText
                id="contact.name"
                label={t("properties:contactPerson")}
                placeholder={t("properties:contactPerson")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={150}
                error={errors.contact && errors.contact.name}
                value={values.contact.name}
                onChange={handleChange}
              />

              <CmsText
                id="contact.telephoneWork"
                label={t("properties:phoneNumberWork")}
                placeholder={t("properties:phoneNumber")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={14}
                error={errors.contact && errors.contact.telephoneWork}
                value={values.contact.telephoneWork}
                onChange={handleChange}
              />

              <CmsText
                id="contact.telephonePrivate"
                label={t("properties:phoneNumberPrivate")}
                placeholder={t("properties:phoneNumber")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={14}
                error={errors.contact && errors.contact.telephonePrivate}
                value={values.contact.telephonePrivate}
                onChange={handleChange}
              />

              <CmsText
                id="contact.emailAddress"
                label={t("properties:email")}
                placeholder={t("properties:email")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={200}
                error={errors.contact && errors.contact.emailAddress}
                value={values.contact.emailAddress}
                onChange={handleChange}
              />

              <CmsText
                id="webAddress"
                label={t("properties:webAddress")}
                placeholder={t("properties:webAddress")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={200}
                error={errors.webAddress}
                value={values.webAddress}
                onChange={handleChange}
              />

              <CmsText
                id="address.addressLine1"
                label={t("properties:addressLine1")}
                placeholder={t("properties:address")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={250}
                error={errors.address && errors.address.addressLine1}
                value={values.address.addressLine1}
                onChange={handleChange}
              />

              <CmsText
                id="address.addressLine2"
                label={t("properties:addressLine2")}
                placeholder={t("properties:address")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={250}
                error={errors.address && errors.address.addressLine2}
                value={values.address.addressLine2}
                onChange={handleChange}
              />

              <CmsText
                id="address.city"
                label={t("properties:city")}
                placeholder={t("properties:city")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={250}
                error={errors.address && errors.address.city}
                value={values.address.city}
                onChange={handleChange}
              />

              <CmsText
                id="address.postalCode"
                label={t("properties:postalCode")}
                placeholder={t("properties:postalCode")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={250}
                error={errors.address && errors.address.postalCode}
                value={values.address.postalCode}
                onChange={handleChange}
              />

              <CmsText
                id="remarks"
                label={t("properties:remarks")}
                placeholder={t("properties:remarks")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={250}
                error={errors.remarks}
                value={values.remarks}
                onChange={handleChange}
              />

              <CmsFormItem label={t("entities:products")}>
                <Table
                  bordered
                  size={"small"}
                  style={{ marginBottom: "16px" }}
                  columns={[
                    { title: t("properties:name"), dataIndex: "name" },
                    {
                      title: t("properties:trademark"),
                      dataIndex: "hasTrademark",
                      render: (hasTrademark) =>
                        hasTrademark ? t("common:yes") : t("common:no"),
                    },
                    {
                      title: t("properties:registered"),
                      dataIndex: "isRegistered",
                      render: (isRegistered) =>
                        isRegistered ? t("common:yes") : t("common:no"),
                    },
                    ...(!!canUpdate
                      ? [
                          {
                            title: (
                              <Button
                                disabled={isLoadingAny}
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                style={{ margin: "0px 2px 0px 2px" }}
                                size="small"
                                onClick={() => {
                                  const newProduct = new Product();
                                  newProduct.organizationId = organization?.id;
                                  setState((prevState) => ({
                                    ...prevState,
                                    selectedProduct: newProduct,
                                    selectedProductIndex: -1,
                                    modalVisible: true,
                                  }));
                                }}
                              />
                            ),
                            dataIndex: "actions",
                            align: "right" as any,
                            render: (_, record: any, index) => (
                              <RowButtonSet
                                onEdit={() =>
                                  setState((prevState) => ({
                                    ...prevState,
                                    selectedProduct: record,
                                    modalVisible: true,
                                    selectedProductIndex: index,
                                  }))
                                }
                                onDelete={() => {
                                  const productsCopy = values.products.slice(0);
                                  productsCopy.splice(index, 1);
                                  setFieldValue("products", productsCopy);
                                  setState((prevState) => ({
                                    ...prevState,
                                    selectedProduct: null,
                                    selectedProductIndex: -1,
                                  }));
                                }}
                              />
                            ),
                          },
                        ]
                      : []),
                  ]}
                  dataSource={values.products}
                  rowKey={(row, index) => row.id || (index as number)}
                  pagination={false}
                />
              </CmsFormItem>
            </CmsForm>

            <ProductDetailsModal
              product={state.selectedProduct as Product}
              visible={state.modalVisible}
              onSave={(product) => {
                const productsCopy = values.products.slice(0);

                if (state.selectedProductIndex >= 0) {
                  productsCopy[state.selectedProductIndex] = product;
                } else {
                  productsCopy.push(product);
                }

                setFieldValue("products", productsCopy);

                setState((prevState) => ({
                  ...prevState,
                  selectedProduct: null,
                  selectedProductIndex: -1,
                  modalVisible: false,
                }));
              }}
              onClose={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectedProduct: null,
                  selectedProductIndex: -1,
                  modalVisible: false,
                }));
              }}
            />
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
