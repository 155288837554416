import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Transfer } from "antd";
import { Formik } from "formik";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Country } from "../../../models/reference_lists/Country";
import { Province } from "../../../models/reference_lists/Province";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm, CmsFormItem, CmsMultilingualText, CmsText } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader, CmsTable, CmsTabs } from "../../common/PageComponents";
import { ProvinceDetailsModal } from "./ProvinceDetailsModal";
import { CurrencySortingOrderModal } from "./CurrencySortingOrderModal";
import { DocumentSupplierModal } from "./DocumentSupplierModal";
import TabPane from "antd/lib/tabs/TabPane";
import { InstructionsList } from "../../data/documents/InstructionsList";
import { useQueryCountry, useSaveCountry } from "../../../queries/countries/detail";
import { useQueryCountries } from "../../../queries/countries/lists";
import {
  MapCountryDocumentSuppliers,
  ValidateCountry,
} from "../../../utilities/helpers/country-helper";
import { useQueryDocumentTypes } from "../../../queries/document-types/lists";
import { useQueryCurrencies } from "../../../queries/currencies/lists";
import { useQueryRegions } from "../../../queries/regions/lists";
import { useCmsContext } from "../../../context/app/CmsContext";

export const CountryDetails = () => {
  const { t, i18n } = useTranslation();
  const { canUpdate } = useAuthorization("country");
  const { push } = useHistory();
  const { id: countryId } = useParams<Entity>();
  const context = useCmsContext();
  const [state, setState] = React.useState<{
    selectedProvince: Province | null;
    selectedCurrencySortingOrderIndex: number;
    selectedDocumentSupplierIndex: number;
    provinceModalShow: boolean;
    currencySortingOrderModalShow: boolean;
    documentSupplierModalShow: boolean;
  }>({
    selectedProvince: null,
    selectedCurrencySortingOrderIndex: -1,
    selectedDocumentSupplierIndex: -1,
    provinceModalShow: false,
    currencySortingOrderModalShow: false,
    documentSupplierModalShow: false,
  });

  const {
    data: country,
    isLoading: isLoadingCountry,
    refetch: refetchCountry,
  } = useQueryCountry(countryId);
  const { mutateAsync: saveCountry, isLoading: isLoadingSaveCountry } = useSaveCountry();
  const { data: countries, isLoading: isLoadingCountries } = useQueryCountries();
  const { data: documentTypes, isLoading: isLoadingDocumentTypes } = useQueryDocumentTypes();
  const { data: currencies, isLoading: isLoadingCurrencies } = useQueryCurrencies();
  const { data: regions, isLoading: isLoadingRegions } = useQueryRegions();

  React.useEffect(() => {
    if (country) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateCountry", {
          isoCode2: country.isoCode2,
          isoCode3: country.isoCode3,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "country",
          name: getLocalizedValue(country?.name) ?? t("common:new"),
        },
      ]);
    }
  }, [country, t]);

  const noDataAny =
    (!country && !!countryId) || !countries || !documentTypes || !currencies || !regions;

  const isLoadingAny =
    (isLoadingCountry && !!countryId) ||
    isLoadingCountries ||
    isLoadingDocumentTypes ||
    isLoadingCurrencies ||
    isLoadingRegions ||
    isLoadingSaveCountry;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={country ?? new Country()}
      enableReinitialize={true}
      validate={(country: Country) => ValidateCountry(country, t)}
      onSubmit={async (country: Country) => {
        await saveCountry(country);
        !country.id ? push(`/countries`) : await refetchCountry();
      }}
    >
      {(formikProps) => {
        const { handleChange, handleSubmit, values, setFieldValue, errors, isValid, dirty } =
          formikProps;

        return (
          <>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title="Country"
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/countries`)}
                />,
                !!canUpdate && (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ),
              ]}
            />
            <CmsTabs destroyInactiveTabPane>
              <TabPane key="documentDetails" tab={t("common:details")}>
                <CmsForm>
                  <CmsMultilingualText
                    label={t("properties:name")}
                    required={true}
                    disabled={isLoadingAny}
                    readOnly={!canUpdate}
                    error={errors.name}
                    value={values.name}
                    onChange={(value) => setFieldValue("name", value)}
                  />

                  <CmsText
                    id="isoCode2"
                    label={t("properties:iso2")}
                    placeholder={t("properties:iso2")}
                    required={true}
                    disabled={isLoadingAny}
                    readOnly={!canUpdate}
                    maxLength={2}
                    error={errors.isoCode2}
                    value={values.isoCode2}
                    onChange={handleChange}
                  />

                  <CmsText
                    id="isoCode3"
                    label={t("properties:iso3")}
                    placeholder={t("properties:iso3")}
                    required={true}
                    disabled={isLoadingAny}
                    readOnly={!canUpdate}
                    maxLength={3}
                    error={errors.isoCode3}
                    value={values.isoCode3}
                    onChange={handleChange}
                  />

                  <CmsFormItem label={t("properties:monetaryMemberSince")}>
                    <DatePicker
                      disabled={isLoadingAny}
                      format="YYYY-MM-DD"
                      value={
                        values.monetaryMemberSince
                          ? dayjs(values.monetaryMemberSince, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(value) => setFieldValue("monetaryMemberSince", value)}
                    />
                  </CmsFormItem>

                  <CmsFormItem label={t("properties:exportable")}>
                    <Checkbox
                      disabled={!canUpdate}
                      checked={values.exportable}
                      onChange={(e) => setFieldValue("exportable", e.target.checked)}
                    />
                  </CmsFormItem>

                  <CmsFormItem label={t("entities:regions")}>
                    <Transfer
                      dataSource={regions.map((region) => ({
                        key: region.id,
                        title: getLocalizedValue(region.name),
                      }))}
                      disabled={!canUpdate || isLoadingAny}
                      targetKeys={values.regionIds}
                      onChange={(value) => {
                        setFieldValue("regionIds", value);
                      }}
                      render={(item) => item.title as any}
                    />
                  </CmsFormItem>

                  <CmsFormItem label={t("entities:currencies")}>
                    <Transfer
                      dataSource={currencies.map((currency) => ({
                        key: currency.id,
                        title: currency.name,
                      }))}
                      disabled={!canUpdate || isLoadingAny}
                      targetKeys={values.currencyIds}
                      onChange={(value) => {
                        setFieldValue("currencyIds", value);
                      }}
                      render={(item) => item.title as any}
                    />
                  </CmsFormItem>

                  <CmsFormItem label={t("entities:provinces")}>
                    <CmsTable
                      rowKey="id"
                      bordered
                      size={"small"}
                      style={{ marginBottom: "16px" }}
                      loading={isLoadingCountries}
                      dataSource={values.provinces ?? []}
                      columns={[
                        {
                          title: t("properties:name"),
                          dataIndex: "name",
                        },
                        {
                          title: t("properties:isoCode"),
                          dataIndex: "isoCode2",
                        },
                      ]}
                      onEdit={(id) => {
                        const province = values.provinces?.find((value) => value.id === id);

                        if (province) {
                          setState((prevState) => ({
                            ...prevState,
                            selectedProvince: province,
                            provinceModalShow: true,
                          }));
                        }
                      }}
                      onDeleteHeader={
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          shape="circle"
                          disabled={isLoadingAny}
                          style={{ margin: "0px 2px" }}
                          size="small"
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              selectedProvince: new Province().withCountryId(country?.id as string),
                              provinceModalShow: true,
                            }));
                          }}
                        />
                      }
                      onDelete={(id) => {
                        const index = values.provinces?.findIndex((province) => province.id === id);

                        if (index !== undefined) {
                          const provincesCopy = values.provinces?.slice(0);

                          provincesCopy?.splice(index, 1);
                          setFieldValue("provinces", provincesCopy);

                          setState((prevState) => ({
                            ...prevState,
                            selectedProvince: null,
                          }));
                        }
                      }}
                    />
                  </CmsFormItem>

                  <CmsFormItem label={t("entities:currencySortingOrder")}>
                    <CmsTable
                      rowKey="name"
                      bordered
                      size={"small"}
                      style={{ marginBottom: "16px" }}
                      loading={isLoadingCountries}
                      dataSource={
                        values.currencySortingOrder
                          ? values.currencySortingOrder?.map((value) => ({
                              name: value,
                            }))
                          : []
                      }
                      columns={[
                        {
                          title: t("properties:name"),
                          dataIndex: "name",
                        },
                      ]}
                      onEdit={(id) => {
                        if (values.currencySortingOrder) {
                          const index = values.currencySortingOrder.findIndex(
                            (value) => value === id,
                          );

                          setState((prevState) => ({
                            ...prevState,
                            currencySortingOrderModalShow: true,
                            selectedCurrencySortingOrderIndex: index,
                          }));
                        }
                      }}
                      onDeleteHeader={
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          shape="circle"
                          disabled={isLoadingAny}
                          style={{ margin: "0px 2px" }}
                          size="small"
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              currencySortingOrderModalShow: true,
                            }));
                          }}
                        />
                      }
                      onDelete={(id) => {
                        if (values.currencySortingOrder) {
                          const sortingOrder = values.currencySortingOrder;

                          const index = sortingOrder.findIndex((value) => value === id);

                          sortingOrder?.splice(index, 1);

                          setFieldValue("currencySortingOrder", sortingOrder);
                        }
                      }}
                    />
                  </CmsFormItem>

                  <CmsFormItem label={t("entities:documentsSuppliedByCountries")}>
                    <CmsTable
                      rowKey="documentTypeId"
                      bordered
                      size={"small"}
                      style={{ marginBottom: "16px" }}
                      loading={isLoadingCountries}
                      dataSource={
                        values.documentsSuppliedByCountries
                          ? MapCountryDocumentSuppliers(
                              documentTypes,
                              countries,
                              values.documentsSuppliedByCountries,
                              "suppliedBy",
                              i18n.language,
                            )
                          : []
                      }
                      columns={[
                        {
                          title: t("entities:documentType"),
                          dataIndex: "documentType",
                        },
                        {
                          title: t("properties:code"),
                          dataIndex: "documentTypeCode",
                        },
                        {
                          title: t("properties:country"),
                          dataIndex: "country",
                        },
                      ]}
                      onEdit={(id) => {
                        if (values.documentsSuppliedByCountries) {
                          const index = values.documentsSuppliedByCountries.findIndex(
                            (value) => value.documentTypeId === id,
                          );

                          setState((prevState) => ({
                            ...prevState,
                            documentSupplierModalShow: true,
                            selectedDocumentSupplierIndex: index,
                          }));
                        }
                      }}
                      onDeleteHeader={
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          shape="circle"
                          disabled={isLoadingAny}
                          style={{ margin: "0px 2px" }}
                          size="small"
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              documentSupplierModalShow: true,
                            }));
                          }}
                        />
                      }
                      onDelete={(id) => {
                        if (values.documentsSuppliedByCountries) {
                          const countryDocumentSuppliers = values.documentsSuppliedByCountries;

                          const index = countryDocumentSuppliers.findIndex(
                            (value) => value.documentTypeId === id,
                          );

                          countryDocumentSuppliers?.splice(index, 1);

                          setFieldValue("documentsSuppliedByCountries", countryDocumentSuppliers);
                        }
                      }}
                    />
                  </CmsFormItem>
                  <CmsFormItem label={t("entities:documentsUsedByCountries")}>
                    <CmsTable
                      rowKey="documentTypeId"
                      bordered
                      size={"small"}
                      style={{ marginBottom: "16px" }}
                      loading={isLoadingCountries}
                      dataSource={
                        values.documentsUsedByCountries
                          ? MapCountryDocumentSuppliers(
                              documentTypes,
                              countries,
                              values.documentsUsedByCountries,
                              "suppliedBy",
                              i18n.language,
                            )
                          : []
                      }
                      columns={[
                        {
                          title: t("entities:documentType"),
                          dataIndex: "documentType",
                        },
                        {
                          title: t("properties:code"),
                          dataIndex: "documentTypeCode",
                        },
                        {
                          title: t("properties:country"),
                          dataIndex: "country",
                        },
                      ]}
                    />
                  </CmsFormItem>
                </CmsForm>
              </TabPane>
              <TabPane
                key="countryInstructions"
                tab={t("common:instructions")}
                disabled={!canUpdate}
              >
                <InstructionsList
                  key={"countryInstructionsList"}
                  instructionsList={values.instructions}
                  onChange={(instructions) => setFieldValue("instructions", instructions)}
                  instructionLocationOptions={[
                    "BANKNOTE_COUNTRY",
                    "COUNTERFEIT_COUNTRY",
                    "DOCUMENT_COUNTRY",
                  ]}
                  defaultInstructionLocationOption="BANKNOTE_COUNTRY"
                />
              </TabPane>
            </CmsTabs>

            <ProvinceDetailsModal
              province={state.selectedProvince ?? new Province()}
              show={state.provinceModalShow}
              onSave={(province) => {
                const provinces = values.provinces?.splice(0);

                const index = provinces?.findIndex(
                  (value) => state.selectedProvince?.id === value.id,
                );

                if (index !== undefined && index >= 0 && provinces) {
                  provinces[index] = province;
                } else {
                  provinces?.push(province);
                }

                setFieldValue("provinces", provinces);

                setState((prevState) => ({
                  ...prevState,
                  selectedProvince: null,
                  selectedProvinceIndex: -1,
                  provinceModalShow: false,
                }));
              }}
              onClose={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectedProvince: null,
                  selectedProvinceIndex: -1,
                  provinceModalShow: false,
                }));
              }}
            />

            <CurrencySortingOrderModal
              key={"countryCurrencyOrderModal"}
              data={values.currencySortingOrder ?? []}
              show={state.currencySortingOrderModalShow}
              editIndex={state.selectedCurrencySortingOrderIndex}
              onSave={(currencySortingOrder) => {
                setFieldValue("currencySortingOrder", currencySortingOrder);

                setState((prevState) => ({
                  ...prevState,
                  currencySortingOrderModalShow: false,
                  selectedCurrencySortingOrderIndex: -1,
                }));
              }}
              onClose={() =>
                setState((prevState) => ({
                  ...prevState,
                  currencySortingOrderModalShow: false,
                  selectedCurrencySortingOrderIndex: -1,
                }))
              }
            />

            <DocumentSupplierModal
              key={"documentSupplierModal"}
              data={values.documentsSuppliedByCountries ?? []}
              editIndex={state.selectedDocumentSupplierIndex}
              documentTypes={documentTypes.filter(
                (documentType) =>
                  values.documentsSuppliedByCountries === undefined ||
                  !values.documentsSuppliedByCountries.some(
                    (value) => value.documentTypeId === documentType.id,
                  ),
              )}
              countries={countries.filter((item) => item.id !== country?.id)}
              show={state.documentSupplierModalShow}
              onSave={(documentSuppliers) => {
                setFieldValue("countryDocumentSuppliers", documentSuppliers);

                setState((prevState) => ({
                  ...prevState,
                  documentSupplierModalShow: false,
                  selectedDocumentSupplierIndex: -1,
                }));
              }}
              onClose={() =>
                setState((prevState) => ({
                  ...prevState,
                  documentSupplierModalShow: false,
                  selectedDocumentSupplierIndex: -1,
                }))
              }
            />
          </>
        );
      }}
    </Formik>
  );
};
