import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import * as _ from "lodash";
import { normalize, schema } from "normalizr";
import React, { useCallback, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import config from "../Config";
import { ErrorResponse } from "../models/ErrorResponse";
import { errorFormatter } from "../utilities/ObjectHelper";
import { useNotification } from "./useNotification";
import { ERROR_CODES } from "../utilities/Constants";

interface ReturnType<T> {
  data: T[];
  isLoading: boolean;
  getAll(options?: RequestOptions): Promise<T[]>;
  get(path: string, options?: RequestOptions): Promise<T>;
  download(url: string, options?: RequestOptions);
  getMultiple(
    ids: string[],
    options?: RequestOptions
  ): Promise<{ [id: string]: T }>;
  getByPost(data: any, options?: RequestOptions): Promise<T[]>;
  post(data: any, options?: RequestOptions): Promise<Response>;
  delete(path: string): Promise<boolean>;
  setCustomErrors(errors: { [status: number]: string }): void;
}

interface RequestOptions {
  contentType?: string;
  path?: string;
  download?: "csv" | "zip";
  postAsFile?: boolean;
  postAsFiles?: boolean;
  querystringParams?: { [key: string]: string };
}

export interface Response {
  isSuccess: boolean;
  data?: any;
}

interface State {
  data: any[];
  isLoading: boolean;
}

export const useApi = <T>(endpoint: string): ReturnType<T> => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const customErrors = useRef<{ [status: number]: string }>([]);
  const { keycloak } = useKeycloak();
  const { notifyError } = useNotification();

  const [state, setState] = useState<State>({
    data: [],
    isLoading: false,
  });

  const getRequestUrl = useCallback(
    (path?: string, querystringParams?: { [key: string]: string }) => {
      let url = `${config.apiUrl}/${endpoint}`;
      if (path) {
        url += `/${path}`;
      }
      if (querystringParams) {
        const queryParams = _.map(
          querystringParams,
          (value, prop) => `${prop}=${value}`
        ).join("&");
        url += "?" + queryParams;
      }

      return url.replace(/(https?:\/\/)|(\/)+/g, "$1$2");
    },
    [endpoint]
  );

  const getRequestHeaders = useCallback(
    (options?: RequestOptions) => {
      const headers: any = {
        Accept: options?.contentType || "application/json",
        "Accept-Language": i18n.language,
        Pragma: "no-cache",
      };

      if (config.authenticationEnabled) {
        if (!keycloak.authenticated) {
          throw new Error("Fatal Error: User is not logged-in.");
        }

        headers.Authorization = `Bearer ${keycloak.token}`;
      }

      return headers;
    },
    [i18n.language, keycloak.token, keycloak.authenticated]
  );

  const getRequestBody = useCallback((data: any, options?: RequestOptions) => {
    let body = data;
    if (options?.postAsFile) {
      const formData = new FormData();
      formData.append("file", data);

      body = formData;
    }

    if (options?.postAsFiles) {
      const formData = new FormData();
      for (let i = 0; i < data.length; i++) {
        formData.append("files", data[i]);
      }

      body = formData;
    }

    return body;
  }, []);

  const handleError = useCallback(
    (response) => {
      if (response) {
        const error = response.data as ErrorResponse;
        const customError = customErrors.current[response.status];

        if (customError) {
          notifyError(ERROR_CODES.BAD_REQUEST, customError);
        } else {
          switch (response.status) {
            case 400:
              if (error.errorDetails) {
                notifyError(
                  error.errorCode ?? ERROR_CODES.BAD_REQUEST,
                  React.createElement("div", null, [
                    error.errorMessage,
                    ...errorFormatter(error.errorDetails).map((detail, index) =>
                      React.createElement("div", { id: index }, `- ${detail}`)
                    ),
                  ])
                );
              } else {
                notifyError(
                  error.errorCode ?? ERROR_CODES.BAD_REQUEST,
                  React.createElement("div", null, [error.errorMessage])
                );
              }
              break;
            case 409:
              notifyError(
                error.errorCode ?? ERROR_CODES.BAD_REQUEST,
                React.createElement("div", null, error.errorMessage)
              );
              break;
            case 403:
              history.push("/forbidden");
              break;
            case 404:
              history.push("/not-found");
              break;
            case 500:
              history.push("/server-error");
              break;
          }
        }
      } else {
        notifyError(ERROR_CODES.SERVER_ERROR, t("errors:networkError"));
      }
    },
    // eslint-disable-next-line
    [history, t]
  );

  const setCustomErrors = useCallback(
    (errors: { [status: number]: string }) => {
      customErrors.current = errors;
    },
    []
  );

  const getAll = useCallback(
    async (options?: RequestOptions): Promise<T[]> => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const response = await axios
        .get(getRequestUrl(options?.path, options?.querystringParams), {
          headers: getRequestHeaders(options),
        })
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            data: response.data,
            isLoading: false,
          }));
          return response.data as T[];
        })
        .catch((error) => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
          handleError(error.response);
        });

      return response || [];
    },
    [getRequestUrl, getRequestHeaders, handleError]
  );

  const httpGet = useCallback(
    async (path: string, options?: RequestOptions): Promise<T> => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await axios
        .get(getRequestUrl(path, options?.querystringParams), {
          headers: getRequestHeaders(options),
        })
        .then((response) => {
          return response.data as T;
        })
        .catch((error) => {
          handleError(error.response);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });

      return response || (null as any);
    },
    [getRequestUrl, getRequestHeaders, handleError]
  );

  const httpDownload = useCallback(
    async (path: string, options?: RequestOptions): Promise<T> => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const response = await axios
        .get(getRequestUrl(path, options?.querystringParams), {
          headers: getRequestHeaders(options),
          responseType: "blob",
        })
        .then((response) => {
          return response.data as T;
        })
        .catch((error) => {
          handleError(error.response);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });

      return response || (null as any);
    },
    [getRequestUrl, getRequestHeaders, handleError]
  );

  const httpGetMultiple = useCallback(
    async (
      ids: string[],
      options?: RequestOptions
    ): Promise<{ [id: string]: T }> => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const requests = ids.map((id) =>
        axios.get(getRequestUrl(id, options?.querystringParams), {
          headers: getRequestHeaders(options),
        })
      );

      const response = await axios
        .all(requests)
        .then((response) => {
          return response.map((r) => r.data as T);
        })
        .catch((error) => {
          handleError(error.response);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });

      if (response) {
        const defaultSchema = new schema.Entity("entitiesWithId");
        const normalizedResponse = normalize(response, [defaultSchema]);
        return normalizedResponse.entities.entitiesWithId ?? (null as any);
      }

      return null as any;
    },
    [getRequestUrl, getRequestHeaders, handleError]
  );

  const httpPost = useCallback(
    async (data: any, options?: RequestOptions) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const response = await axios
        .post(
          getRequestUrl(options?.path, options?.querystringParams),
          getRequestBody(data, options),
          { headers: getRequestHeaders(options) }
        )
        .then((response) => {
          return { isSuccess: true, data: response.data };
        })
        .catch((error) => {
          handleError(error.response);
          return { isSuccess: false };
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });

      return response;
    },
    [getRequestUrl, getRequestHeaders, getRequestBody, handleError]
  );

  const httpPostGet = useCallback(
    async (data?: any, options?: RequestOptions): Promise<T[]> => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const response = await axios
        .post(
          getRequestUrl(options?.path, options?.querystringParams),
          getRequestBody(data, options),
          { headers: getRequestHeaders(options) }
        )
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            data: response.data,
            isLoading: false,
          }));
          return response.data.map((r) => r.data as T);
        })
        .catch((error) => {
          handleError(error.response);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });

      return response || null;
    },
    [getRequestUrl, getRequestHeaders, getRequestBody, handleError]
  );

  const httpDelete = useCallback(
    async (path: string) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const success = axios
        .delete(getRequestUrl(path), { headers: getRequestHeaders() })
        .then((_) => {
          return true;
        })
        .catch((error) => {
          handleError(error.response);
          return false;
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });

      return success;
    },
    [getRequestUrl, getRequestHeaders, handleError]
  );

  return {
    data: state.data,
    isLoading: state.isLoading,
    getAll,
    get: httpGet,
    download: httpDownload,
    getMultiple: httpGetMultiple,
    getByPost: httpPostGet,
    post: httpPost,
    delete: httpDelete,
    setCustomErrors,
  };
};
