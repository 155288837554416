import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, TreeSelect } from "antd";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { MultilingualInputData } from "../../../models/MultilingualInputData";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { DocumentTemplate } from "../../../models/templates/DocumentTemplate";
import { ImageTemplate } from "../../../models/templates/ImageTemplate";
import { ViewTemplate } from "../../../models/templates/ViewTemplate";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import {
  addItem,
  rearrangeItems,
  removeItem,
} from "../../../utilities/ListHelpers";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { DragSortTable } from "../../common/DragSortTable";
import { CmsForm } from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import {
  CmsContent,
  CmsPageHeader,
  CmsPageLoader,
  CmsSider,
} from "../../common/PageComponents";
import { RowButtonSet } from "../../common/RowButtonSet";
import { AttributeLinkDetailsModal } from "./../attributeLinks/AttributeLinkDetailsModal";
import "./DocumentTemplateDetails.scss";
import { DocumentTemplateTree } from "./DocumentTemplateTree";
import { ImageTemplateDetails } from "./ImageTemplateDetails";
import { ViewTemplateDetails } from "./ViewTemplateDetails";
import { ValidateDocumentTemplate } from "../../../utilities/helpers/document-template-helper";
import {
  useQueryDocumentTemplate,
  useSaveDocumentTemplate,
} from "../../../queries/document-template/detail";
import { useQueryDocumentTemplateGroups } from "../../../queries/document-template-groups/lists";
import { useCmsContext } from "../../../context/app/CmsContext";

export const DocumentTemplateDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("documentTemplate");
  const { push } = useHistory();
  const { id: documentTemplateId } = useParams<Entity>();
  const context = useCmsContext();
  const [state, setState] = React.useState<{
    viewTemplateIndex: number | null;
    imageTemplateIndex: number | null;
    selectedAttributeLink: AttributeLink | null;
    selectedAttributeLinkIndex: number | null;
    attributeLinkModalVisible: boolean;
  }>({
    viewTemplateIndex: null,
    imageTemplateIndex: null,
    selectedAttributeLink: null,
    selectedAttributeLinkIndex: null,
    attributeLinkModalVisible: false,
  });

  const {
    data: documentTemplate,
    isLoading: isLoadingDocumentTemplate,
    refetch: refetchDocumentTemplate,
  } = useQueryDocumentTemplate(documentTemplateId);
  const {
    mutateAsync: saveDocumentTemplate,
    isLoading: isSavingDocumentTemplate,
  } = useSaveDocumentTemplate();
  const {
    data: documentTemplateGroups,
    isLoading: isLoadingDocumentTemplateGroups,
  } = useQueryDocumentTemplateGroups();

  useEffect(() => {
    if (documentTemplate) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateDocumentTemplate", {
          name: documentTemplate.name,
        })
      );

      context?.setBreadcrumbItems([
        {
          key: "documentTemplate",
          name: documentTemplate.name ?? t("common:new"),
        },
      ]);
    }
  }, [documentTemplate, t]);

  const noDataAny =
    (!documentTemplate && !!documentTemplateId) || !documentTemplateGroups;

  const isLoadingAny =
    (isLoadingDocumentTemplate && !!documentTemplateId) ||
    isSavingDocumentTemplate ||
    isLoadingDocumentTemplateGroups;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={documentTemplate ?? new DocumentTemplate()}
      enableReinitialize={true}
      validate={(documentTemplate: DocumentTemplate) =>
        ValidateDocumentTemplate(documentTemplate, t)
      }
      onSubmit={async (documentTemplate: DocumentTemplate) => {
        await saveDocumentTemplate(documentTemplate);
        !documentTemplate.id
          ? push(`/document-templates`)
          : await refetchDocumentTemplate();
      }}
    >
      {(formikProps) => {
        const {
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title={t("entities:documentTemplate")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/document-templates`)}
                />,
                !!canUpdate ? (
                  <CmsSaveButton
                    key="save"
                    disabled={!dirty || !isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ) : null,
              ]}
            />

            <Layout>
              <CmsSider>
                <DocumentTemplateTree
                  title={documentTemplate?.name ?? t("common:new")}
                  documentTemplate={values}
                  selectedItem={{
                    viewTemplateIndex: state.viewTemplateIndex as number,
                    imageTemplateIndex: state.imageTemplateIndex as number,
                  }}
                  readOnly={!canUpdate}
                  onSelectItem={(viewTemplateIndex, imageTemplateIndex) => {
                    setState((prevState) => ({
                      ...prevState,
                      viewTemplateIndex,
                      imageTemplateIndex,
                    }));
                  }}
                  onAddViewTemplate={() => {
                    let viewTemplates = values.viewTemplates;
                    viewTemplates = addItem(
                      new ViewTemplate().withDocumentTemplate(
                        values.id as string
                      ),
                      viewTemplates
                    );

                    setFieldValue("viewTemplates", viewTemplates);
                    setState((prevState) => ({
                      ...prevState,
                      viewTemplateIndex: viewTemplates.length - 1,
                      imageTemplateIndex: null,
                    }));
                  }}
                  onDeleteViewTemplate={(viewTemplateIndex) => {
                    let viewTemplates = values.viewTemplates;
                    viewTemplates = removeItem(
                      viewTemplateIndex,
                      viewTemplates
                    );

                    setFieldValue("viewTemplates", viewTemplates);

                    if (viewTemplateIndex === state.viewTemplateIndex) {
                      setState((prevState) => ({
                        ...prevState,
                        viewTemplateIndex: null,
                        imageTemplateIndex: null,
                      }));
                    }
                  }}
                  onAddImageTemplate={(viewTemplateIndex) => {
                    let imageTemplates =
                      values.viewTemplates[viewTemplateIndex].imageTemplates;
                    imageTemplates = addItem(
                      new ImageTemplate().withViewTemplateId(
                        values.viewTemplates[viewTemplateIndex].id as string
                      ),
                      imageTemplates
                    );

                    setFieldValue(
                      `viewTemplates[${viewTemplateIndex}].imageTemplates`,
                      imageTemplates
                    );
                    setState((prevState) => ({
                      ...prevState,
                      viewTemplateIndex,
                      imageTemplateIndex: imageTemplates.length - 1,
                    }));
                  }}
                  onDeleteImageTemplate={(
                    imageTemplateIndex,
                    viewTemplateIndex
                  ) => {
                    let imageTemplates =
                      values.viewTemplates[viewTemplateIndex].imageTemplates;
                    imageTemplates = removeItem(
                      imageTemplateIndex,
                      imageTemplates
                    );

                    setFieldValue(
                      `viewTemplates[${viewTemplateIndex}].imageTemplates`,
                      imageTemplates
                    );

                    if (imageTemplateIndex === state.imageTemplateIndex) {
                      setState((prevState) => ({
                        ...prevState,
                        imageTemplateIndex: null,
                      }));
                    }
                  }}
                />
              </CmsSider>
              <CmsContent>
                {state.viewTemplateIndex === null &&
                state.imageTemplateIndex === null ? (
                  <React.Fragment>
                    <CmsForm>
                      <Form.Item label={t("properties:group")} required={true}>
                        <TreeSelect
                          style={{ width: "100%", marginTop: "5px" }}
                          placeholder={t("properties:group")}
                          disabled={!canUpdate || isLoadingAny}
                          treeDefaultExpandAll={true}
                          treeData={convertGroupsForTreeSelect(
                            documentTemplateGroups
                          )}
                          value={values.groupId}
                          id="groupId"
                          onChange={(e) => setFieldValue("groupId", e)}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("properties:name")}
                        validateStatus={!errors.name ? "success" : "error"}
                        help={errors.name}
                        required={true}
                      >
                        <Input
                          type="text"
                          placeholder={t("properties:name")}
                          disabled={isLoadingAny}
                          readOnly={!canUpdate}
                          maxLength={50}
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("properties:caption")}
                        validateStatus={!errors.caption ? "success" : "error"}
                        help={errors.caption}
                      >
                        <MultilingualInput
                          disabled={isLoadingAny}
                          readOnly={!canUpdate}
                          mlData={values.caption}
                          onChange={(e) => setFieldValue("caption", e)}
                        />
                      </Form.Item>
                      <Form.Item label={t("entities:attributes")}>
                        <DragSortTable
                          bordered
                          size={"small"}
                          onRow={() =>
                            ({
                              moveRow: (fromIndex, toIndex) =>
                                setFieldValue(
                                  "attributeLinks",
                                  rearrangeItems(
                                    values.attributeLinks,
                                    fromIndex,
                                    toIndex
                                  )
                                ),
                            }) as any
                          }
                          columns={[
                            {
                              title: t("entities:attribute"),
                              dataIndex: "attributeName",
                            },
                            {
                              title: t("properties:caption"),
                              dataIndex: "caption",
                              render: (caption: MultilingualInputData) =>
                                caption ? (
                                  <span>{getLocalizedValue(caption)}</span>
                                ) : null,
                            },
                            {
                              title: t("properties:exportable"),
                              dataIndex: "exportable",
                              render: (value) =>
                                t(`common:${value ? "yes" : "no"}`),
                            },
                            {
                              title: capitalizeFirstLetter(
                                t("properties:filterable")
                              ),
                              dataIndex: "filterable",
                              render: (value) =>
                                t(`common:${value ? "yes" : "no"}`),
                            },
                            {
                              title: t("properties:required"),
                              dataIndex: "isRequired",
                              render: (value) =>
                                t(`common:${value ? "yes" : "no"}`),
                            },
                            ...(!!canUpdate
                              ? [
                                  {
                                    title: (
                                      <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        disabled={isLoadingAny}
                                        style={{ margin: "0px 2px" }}
                                        size="small"
                                        onClick={() => {
                                          setState((prevState) => ({
                                            ...prevState,
                                            selectedAttributeLink:
                                              new AttributeLink(),
                                            selectedAttributeLinkIndex: -1,
                                            attributeLinkModalVisible: true,
                                          }));
                                        }}
                                      />
                                    ),
                                    dataIndex: "actions",
                                    align: "right" as any,
                                    render: (text: any, record: any, index) => (
                                      <RowButtonSet
                                        onEdit={() =>
                                          setState((prevState) => ({
                                            ...prevState,
                                            selectedAttributeLink: record,
                                            attributeLinkModalVisible: true,
                                            selectedAttributeLinkIndex: index,
                                          }))
                                        }
                                        onDelete={() => {
                                          const attributeLinks = removeItem(
                                            index,
                                            values.attributeLinks
                                          );
                                          setFieldValue(
                                            "attributeLinks",
                                            attributeLinks
                                          );
                                          setState((prevState) => ({
                                            ...prevState,
                                            selectedAttributeLink: null,
                                            selectedAttributeLinkIndex: -1,
                                          }));
                                        }}
                                      />
                                    ),
                                  },
                                ]
                              : []),
                          ]}
                          dataSource={values.attributeLinks}
                          rowKey={(row, index) => row.id || (index as number)}
                          pagination={false}
                          readOnly={!canUpdate}
                        />
                      </Form.Item>
                      <Form.Item label={t("properties:order")}>
                        <DragSortTable
                          bordered
                          size={"small"}
                          onRow={(record, index) =>
                            ({
                              moveRow: (fromIndex, toIndex) =>
                                setFieldValue(
                                  "viewTemplates",
                                  rearrangeItems(
                                    values.viewTemplates,
                                    fromIndex,
                                    toIndex
                                  )
                                ),
                            }) as any
                          }
                          columns={[
                            {
                              title: t("properties:name"),
                              dataIndex: "name",
                            },
                          ]}
                          dataSource={values.viewTemplates}
                          rowKey={(row, index) => row.id || (index as number)}
                          pagination={false}
                          readOnly={!canUpdate}
                        />
                      </Form.Item>
                    </CmsForm>
                    {state.attributeLinkModalVisible &&
                    state.selectedAttributeLink ? (
                      <AttributeLinkDetailsModal
                        attributeLink={state.selectedAttributeLink}
                        onSave={(attributeLink) => {
                          let attributeLinks = values.attributeLinks;

                          if (
                            state.selectedAttributeLinkIndex &&
                            state.selectedAttributeLinkIndex >= 0
                          ) {
                            attributeLinks[state.selectedAttributeLinkIndex] =
                              attributeLink;
                          } else {
                            attributeLinks = addItem(
                              attributeLink,
                              attributeLinks
                            );
                          }

                          setFieldValue("attributeLinks", attributeLinks);

                          setState((prevState) => ({
                            ...prevState,
                            selectedAttributeLink: null,
                            selectedAttributeLinkIndex: -1,
                            attributeLinkModalVisible: false,
                          }));
                        }}
                        onClose={() => {
                          setState((prevState) => ({
                            ...prevState,
                            selectedAttributeLink: null,
                            selectedAttributeLinkIndex: -1,
                            attributeLinkModalVisible: false,
                          }));
                        }}
                      />
                    ) : null}
                  </React.Fragment>
                ) : null}
                {state.viewTemplateIndex !== null && (
                  <ViewTemplateDetails
                    key={`viewTemplate_${state.viewTemplateIndex}`}
                    disabled={isLoadingAny}
                    readOnly={!canUpdate}
                    viewTemplateIndex={state.viewTemplateIndex}
                  />
                )}
                {state.imageTemplateIndex !== null &&
                state.viewTemplateIndex !== null ? (
                  <ImageTemplateDetails
                    key={`imageTemplate_${state.viewTemplateIndex}_${state.imageTemplateIndex}`}
                    disabled={isLoadingAny}
                    readOnly={!canUpdate}
                    viewTemplateIndex={state.viewTemplateIndex}
                    imageTemplateIndex={state.imageTemplateIndex}
                  />
                ) : null}
              </CmsContent>
            </Layout>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
