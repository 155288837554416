import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  TimePicker,
} from "antd";
import { ColProps } from "antd/lib/col";
import update from "immutability-helper";
import * as _ from "lodash";
import dayjs from "dayjs";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../../hooks/useApi";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { AttributeValue } from "../../../models/data/AttributeValue";
import {
  DateTimeFormat,
  getDefaultSchema,
  IDateTimeSchema,
  IListSchema,
  INumberSchema,
  IObjectItem,
} from "../../../models/InputComponentData";
import { Country } from "../../../models/reference_lists/Country";
import { Language } from "../../../models/reference_lists/Language";
import { MaterialType } from "../../../models/reference_lists/MaterialType";
import { Organization } from "../../../models/reference_lists/Organization";
import { Product } from "../../../models/reference_lists/Product";
import { Province } from "../../../models/reference_lists/Province";
import { Region } from "../../../models/reference_lists/Region";
import { DocumentType } from "../../../models/reference_lists/DocumentType";
import {
  Attribute,
  AttributeDataType,
} from "../../../models/templates/Attribute";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { validateAttributeValue } from "../../../utilities/AttributeValueValidator";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { hasSameKeys, hasSameValues } from "../../../utilities/ObjectHelper";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import {
  CmsColLayout,
  CmsForm,
  CmsFormItem,
  CmsFormLayout,
} from "../../common/FormComponents";
import { MultilingualInput } from "../../common/MultilingualInput";
import { CmsPageLoader } from "../../common/PageComponents";
import { MultilingualInputData } from "../../../models/MultilingualInputData";

interface ExtendedAttributeLink {
  id: string;
  caption: string;
  dataType: AttributeDataType;
  schema: any;
  defaultValue: string;
  required: boolean;
}

interface Props {
  attributeLinks: AttributeLink[];
  attributeValues: AttributeValue[];
  formLayout?: {
    labelCol?: ColProps;
    wrapperCol?: ColProps;
  };
  colLayout?: ColProps;
  labelAlign?: "left" | "right";
  renderMode?: "onecolumn" | "twocolumns";
  onChange(attributeValues: AttributeValue[]): void;
  onValidityChanged?(isValid: boolean);
}

interface State {
  extendedAttributeLinks: ExtendedAttributeLink[];
  errors: { [attributeLinkId: string]: string };
  loading: boolean;
}

export const AttributeValueEntries = (props: Props) => {
  const { canUpdate } = useAuthorization("document");

  const { onValidityChanged } = props;

  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    extendedAttributeLinks: [],
    errors: {},
    loading: false,
  });

  const { get: getAttribute } = useApi<Attribute>("attributes");
  const {
    getAll: getCountries,
    data: countries,
    isLoading: isLoadingCountries,
  } = useApi<Country>("countries");
  const {
    getAll: getProvinces,
    data: provinces,
    isLoading: isLoadingProvinces,
  } = useApi<Province>("provinces");
  const {
    getAll: getCurrencies,
    data: currencies,
    isLoading: isLoadingCurrencies,
  } = useApi<Country>("currencies");
  const {
    getAll: getLanguages,
    data: languages,
    isLoading: isLoadingLanguages,
  } = useApi<Language>("languages");
  const {
    getAll: getRegions,
    data: regions,
    isLoading: isLoadingRegions,
  } = useApi<Region>("regions");
  const {
    getAll: getOrganizations,
    data: organizations,
    isLoading: isLoadingOrganizations,
  } = useApi<Organization>("organizations");
  const {
    getAll: getProducts,
    data: products,
    isLoading: isLoadingProducts,
  } = useApi<Product>("products");
  const {
    getAll: getDocumentTypes,
    data: documentTypes,
    isLoading: isLoadingDocumentTypes,
  } = useApi<DocumentType>("documenttypes");
  const {
    getAll: getMaterialTypes,
    data: materialTypes,
    isLoading: isLoadingMaterialTypes,
  } = useApi<MaterialType>("materialTypes");

  const isLoading =
    state.loading ||
    isLoadingCountries ||
    isLoadingProvinces ||
    isLoadingCurrencies ||
    isLoadingLanguages ||
    isLoadingRegions ||
    isLoadingOrganizations ||
    isLoadingProducts ||
    isLoadingDocumentTypes ||
    isLoadingMaterialTypes;

  useEffect(() => {
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.Country
      )
    ) {
      getCountries();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.Province
      )
    ) {
      getProvinces();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.Currency
      )
    ) {
      getCurrencies();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.DocumentType
      )
    ) {
      getDocumentTypes();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.Language
      )
    ) {
      getLanguages();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.MaterialType
      )
    ) {
      getMaterialTypes();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.Organization
      )
    ) {
      getOrganizations();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.Product
      )
    ) {
      getProducts();
    }
    if (
      _.some(
        state.extendedAttributeLinks,
        (link) => link.dataType === AttributeDataType.Region
      )
    ) {
      getRegions();
    }
  }, [
    getCountries,
    getProvinces,
    getCurrencies,
    getLanguages,
    getRegions,
    getOrganizations,
    getProducts,
    getDocumentTypes,
    getMaterialTypes,
    state.extendedAttributeLinks,
  ]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, loading: true }));

    (async () => {
      const attributes = await Promise.all(
        props.attributeLinks
          .map((attributeLink) => attributeLink.attributeId)
          .map(async (id) => await getAttribute(id as string))
      );

      const orderedAttributeLinks: AttributeLink[] = _.orderBy(
        props.attributeLinks,
        "order",
        "asc"
      );

      const extendedAttributeLinks: ExtendedAttributeLink[] =
        orderedAttributeLinks.map((attributeLink) => {
          const attribute = attributes.find(
            (attr) => attr.id === attributeLink.attributeId
          );

          return {
            id: attributeLink.id as string,
            caption: capitalizeFirstLetter(
              getLocalizedValue(attributeLink.caption) ||
                getLocalizedValue(attribute?.caption) ||
                attribute?.name ||
                ""
            ),
            dataType: attribute?.dataType as AttributeDataType,
            schema:
              attribute?.dataTypeSchema ||
              getDefaultSchema(attribute?.dataType as AttributeDataType),
            defaultValue: (attributeLink.defaultValue ||
              attribute?.defaultValue) as string,
            required: attributeLink.isRequired as boolean,
          };
        });

      setState((prevState) => ({
        ...prevState,
        extendedAttributeLinks,
        loading: false,
      }));
    })();
  }, [props.attributeLinks, getAttribute]);

  useEffect(() => {
    const errors = {};
    state.extendedAttributeLinks.forEach((extendedAttributeLink) => {
      const attributeValue = props.attributeValues.find(
        (a) => a.attributeLinkId === extendedAttributeLink.id
      );
      const value = attributeValue
        ? attributeValue.value
        : extendedAttributeLink.defaultValue;

      if (value) {
        const error = validateAttributeValue(
          extendedAttributeLink.dataType,
          extendedAttributeLink.schema,
          extendedAttributeLink.required,
          extendedAttributeLink.caption,
          value
        );
        if (error) {
          errors[extendedAttributeLink.id] = error;
        }
      }
    });

    if (
      !hasSameKeys(errors, state.errors) ||
      !hasSameValues(errors, state.errors)
    ) {
      setState((prevState) => ({ ...prevState, errors }));
      if (onValidityChanged) {
        onValidityChanged(Object.keys(errors).length === 0);
      }
    }
  }, [
    onValidityChanged,
    props.attributeValues,
    state.extendedAttributeLinks,
    state.errors,
  ]);

  const onChange = (attributeLink: ExtendedAttributeLink, value: any) => {
    const index = props.attributeValues.findIndex(
      (a) => a.attributeLinkId === attributeLink.id
    );
    if (index === -1) {
      const newAttributeValue = { attributeLinkId: attributeLink.id, value };
      const attributeValues = update(props.attributeValues, {
        $push: [newAttributeValue],
      });
      props.onChange(attributeValues);
    } else {
      const attributeValues = update(props.attributeValues, {
        [index]: { value: { $set: value } },
      });
      props.onChange(attributeValues);
    }
  };

  const generateInput = (extendedAttributeLink: ExtendedAttributeLink) => {
    const attributeValue = props.attributeValues.find(
      (a) => a.attributeLinkId === extendedAttributeLink.id
    );

    const value = attributeValue
      ? attributeValue.value
      : extendedAttributeLink.defaultValue;

    switch (extendedAttributeLink.dataType) {
      case AttributeDataType.Text:
        return (
          <Input
            readOnly={!canUpdate}
            value={value}
            onChange={(e) =>
              onChange(extendedAttributeLink, e.currentTarget.value)
            }
          />
        );
      case AttributeDataType.MultiLingualText:
        const mlDataValue = value ? JSON.parse(value) : {};
        return (
          <MultilingualInput
            readOnly={!canUpdate}
            mlData={mlDataValue}
            onChange={(e) => onChange(extendedAttributeLink, e)}
          />
        );
      case AttributeDataType.Number:
        const numberSchema = extendedAttributeLink.schema as INumberSchema;
        const decimalCount =
          isNaN(numberSchema.decimalCount) || numberSchema.decimalCount === null
            ? undefined
            : numberSchema.decimalCount;
        const parsedValue =
          value === typeof "string" ? parseFloat(value) : undefined;
        return (
          <InputNumber
            readOnly={!canUpdate}
            value={parsedValue}
            onChange={(value) => {
              if (value) {
                const parsedValue = parseFloat(value.toString());
                onChange(
                  extendedAttributeLink,
                  decimalCount ? parsedValue.toFixed(decimalCount) : parsedValue
                );
              } else {
                onChange(extendedAttributeLink, value);
              }
            }}
            precision={decimalCount}
          />
        );
      case AttributeDataType.DateTime:
        const dschema = extendedAttributeLink.schema as IDateTimeSchema;
        if (dschema.format === DateTimeFormat.Date) {
          const parsedValue = value ? dayjs(value, "YYYY-MM-DD") : null;
          return (
            <DatePicker
              disabled={!canUpdate}
              format="YYYY-MM-DD"
              value={parsedValue}
              onChange={(_, value) => onChange(extendedAttributeLink, value)}
            />
          );
        } else if (dschema.format === DateTimeFormat.DateTime) {
          const parsedValue = value ? dayjs(value, "YYYY-MM-DD HH:mm") : null;
          return (
            <DatePicker
              disabled={!canUpdate}
              format="YYYY-MM-DD HH:mm"
              showTime={{ format: "HH:mm" }}
              value={parsedValue}
              onChange={(_, value) => onChange(extendedAttributeLink, value)}
            />
          );
        } else if (dschema.format === DateTimeFormat.Time) {
          const parsedValue = value ? dayjs(value, "HH:mm") : null;
          return (
            <TimePicker
              disabled={!canUpdate}
              format="HH:mm"
              value={parsedValue}
              onChange={(_, value) => onChange(extendedAttributeLink, value)}
            />
          );
        } else if (dschema.format === DateTimeFormat.YearAndMonth) {
          const parsedValue = value ? dayjs(value, "YYYY-MM") : null;
          return (
            <DatePicker.MonthPicker
              disabled={!canUpdate}
              format="YYYY-MM"
              value={parsedValue}
              onChange={(_, value) => onChange(extendedAttributeLink, value)}
            />
          );
        } else if (dschema.format === DateTimeFormat.Year) {
          const parsedValue = value ? dayjs(value, "YYYY") : null;
          return (
            <DatePicker.MonthPicker
              disabled={!canUpdate}
              format="YYYY"
              value={parsedValue}
              onChange={(_, value) => onChange(extendedAttributeLink, value)}
            />
          );
        }
        break;
      case AttributeDataType.YesNo:
        return (
          <Checkbox
            disabled={!canUpdate}
            checked={(value && value.toLowerCase() === "true") as boolean}
            onChange={(e) =>
              onChange(extendedAttributeLink, e.target.checked.toString())
            }
          />
        );
      case AttributeDataType.List:
        const lschema = extendedAttributeLink.schema as IListSchema;
        return (
          <Select
            showSearch
            disabled={!canUpdate}
            mode={!lschema.multiSelect ? undefined : "multiple"}
            allowClear={true}
            autoClearSearchValue={false}
            value={value ?? undefined}
            placeholder={extendedAttributeLink.caption}
            filterOption={(input, option) =>
              option?.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => onChange(extendedAttributeLink, value)}
          >
            {_.map(lschema.items, (item, index) => {
              return (
                <Select.Option
                  key={index}
                  title={getLocalizedValue(item.name)}
                  value={item.code}
                >
                  {getLocalizedValue(item.name)}
                </Select.Option>
              );
            })}
          </Select>
        );
      case AttributeDataType.Country:
        return select(countries, value, extendedAttributeLink);
      case AttributeDataType.Province:
        return select(provinces, value, extendedAttributeLink);
      case AttributeDataType.Currency:
        return select(currencies, value, extendedAttributeLink);
      case AttributeDataType.Language:
        return select(languages, value, extendedAttributeLink);
      case AttributeDataType.Region:
        return select(regions, value, extendedAttributeLink);
      case AttributeDataType.Organization:
        return select(organizations, value, extendedAttributeLink);
      case AttributeDataType.Product:
        return select(products, value, extendedAttributeLink);
      case AttributeDataType.DocumentType:
        return select(documentTypes, value, extendedAttributeLink);
      case AttributeDataType.MaterialType:
        return select(materialTypes, value, extendedAttributeLink);
      case AttributeDataType.Object:
        let data = value ? (JSON.parse(value) as IObjectItem[]) : null;

        if (data === null) data = [];

        const objectChange = () =>
          onChange(extendedAttributeLink, JSON.stringify(data));

        const objectEdit = (
          value: string,
          index: number,
          type: "key" | "value"
        ) => {
          if (data === null) return;

          data[index][type] = value;
          objectChange();
        };

        return (
          <table style={{ width: "100%" }}>
            <tr>
              <th style={{ width: "200px", fontWeight: "bold" }}>&nbsp;</th>
              <th style={{ width: "200px", fontWeight: "bold" }}>&nbsp;</th>
              <th>
                <Button
                  type="primary"
                  disabled={!canUpdate}
                  shape="circle"
                  icon={<PlusOutlined />}
                  style={{ margin: "4px" }}
                  size="small"
                  onClick={() => {
                    if (data === null) return;

                    data.push({ key: "", value: "" });
                    objectChange();
                  }}
                />
              </th>
            </tr>
            {data.map((objectData, index) => (
              <tr>
                <td>
                  <Input
                    readOnly={!canUpdate}
                    value={objectData.key}
                    onChange={(e) =>
                      objectEdit(e.currentTarget.value, index, "key")
                    }
                  />
                </td>
                <td>
                  <Input
                    readOnly={!canUpdate}
                    value={objectData.value}
                    onChange={(e) =>
                      objectEdit(e.currentTarget.value, index, "value")
                    }
                  />
                </td>
                <td>
                  <Popconfirm
                    title={t("common:confirmDelete")}
                    onConfirm={() => {
                      if (data === null) return;

                      data.splice(index, 1);
                      objectChange();
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      key="delete"
                      disabled={!canUpdate}
                      style={{ margin: "4px" }}
                      danger
                      type="primary"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      size="small"
                    />
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </table>
        );
    }
  };

  const select = <
    T extends {
      id?: string;
      name?: string | MultilingualInputData;
      isoCode?: string;
    }
  >(
    dataList: T[],
    defaultValue?: string,
    attributeLink?: ExtendedAttributeLink
  ): JSX.Element => {
    return (
      <Select
        showSearch
        disabled={!canUpdate}
        allowClear={true}
        value={defaultValue ?? undefined}
        filterOption={(input, option) =>
          option?.props.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        onChange={(value) => attributeLink && onChange(attributeLink, value)}
      >
        {dataList.map(
          (data) =>
            data.id && (
              <Select.Option key={data.id} value={data.id}>
                {data.isoCode ? `${data.isoCode} - ` : undefined}
                {typeof data.name !== "object"
                  ? data.name
                  : getLocalizedValue(data.name)}
              </Select.Option>
            )
        )}
      </Select>
    );
  };

  const splitIndex = Math.ceil(state.extendedAttributeLinks.length / 2);
  const formItems = state.extendedAttributeLinks.map(
    (extendedAttributeLink) => (
      <CmsFormItem
        key={extendedAttributeLink.id}
        label={extendedAttributeLink.caption}
        labelAlign={props.labelAlign}
        error={state.errors[extendedAttributeLink.id]}
      >
        {generateInput(extendedAttributeLink)}
      </CmsFormItem>
    )
  );

  const formLayout = props.formLayout || CmsFormLayout.twocolumn;
  const colLayout: ColProps = props.colLayout || CmsColLayout;

  return (
    <CmsPageLoader loading={isLoading} subTitle={t("common:loadingData")}>
      <Row>
        {props.renderMode === "onecolumn" ? (
          <Col {...colLayout}>
            <CmsForm {...formLayout}>{formItems}</CmsForm>
          </Col>
        ) : (
          <React.Fragment>
            <Col {...colLayout}>
              <CmsForm {...formLayout}>
                {formItems.slice(0, splitIndex)}
              </CmsForm>
            </Col>
            <Col {...colLayout}>
              <CmsForm {...formLayout}>{formItems.slice(splitIndex)}</CmsForm>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </CmsPageLoader>
  );
};
