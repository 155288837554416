import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { DocumentType } from "../../../models/reference_lists/DocumentType";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import {
  CmsForm,
  CmsMultilingualText,
  CmsText,
} from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import {
  useQueryDocumentType,
  useSaveDocumentType,
} from "../../../queries/document-types/detail";
import { ValidateDocumentType } from "../../../utilities/helpers/document-type-helper";
import { useCmsContext } from "../../../context/app/CmsContext";

export const DocumentTypeDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("documentType");
  const { push } = useHistory();
  const { id: documentTypeId } = useParams<Entity>();
  const context = useCmsContext();

  const {
    data: documentType,
    isLoading: isLoadingDocumentType,
    refetch: refetchDocumentType,
  } = useQueryDocumentType(documentTypeId);
  const {
    mutateAsync: saveDocumentType,
    isLoading: isLoadingSaveDocumentType,
  } = useSaveDocumentType();

  React.useEffect(() => {
    if (documentType) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateDocumentType", {
          isoCode: documentType.code,
        })
      );

      context?.setBreadcrumbItems([
        {
          key: "document-type",
          name: documentType.name ?? t("common:new"),
        },
      ]);
    }
  }, [documentType, t]);

  const isLoadingAny =
    (isLoadingDocumentType && !!documentTypeId) || isLoadingSaveDocumentType;
  const noDataAny = !documentType && !!documentTypeId;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={documentType ?? new DocumentType()}
      enableReinitialize={true}
      validate={(documentType: DocumentType) =>
        ValidateDocumentType(documentType, t)
      }
      onSubmit={async (documentType: DocumentType) => {
        await saveDocumentType(documentType);
        !documentType.id
          ? push(`/document-types`)
          : await refetchDocumentType();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title={t("entities:documentType")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/document-types`)}
                />,
                !!canUpdate ? (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ) : null,
              ]}
            />
            <CmsForm>
              <CmsText
                id="name"
                label={t("properties:name")}
                placeholder={t("properties:name")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={100}
                error={errors.name}
                value={values.name}
                onChange={handleChange}
              />

              <CmsMultilingualText
                label={t("properties:caption")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                error={errors.caption}
                value={values.caption}
                onChange={(value) => setFieldValue("caption", value)}
              />

              <CmsText
                id="code"
                label={t("properties:code")}
                placeholder={t("properties:code")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={2}
                error={errors.code}
                value={values.code}
                onChange={handleChange}
              />
            </CmsForm>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
