import {
  WarningOutlined,
  UnlockOutlined,
  LockOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { EntityType } from "../../../models/data/DC/EntityType";
import { ActionType } from "../../../models/data/DC/ActionType";
import { NotificationType } from "../../../models/data/DC/NotificationType";
import { Notification } from "../../../models/data/DC/Notification";
import { DocumentSeries, DocumentStatus } from "../../../models/data/Document";
import { Entity } from "../../../models/data/Entity";
import classNames from "classnames";
import { default as config } from "../../../Config";
import dayjs from "dayjs";
import { CmsBackButton, CmsButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsPageHeader, CmsPageLoader, CmsTabs } from "../../common/PageComponents";
import { AttributeValueEntries } from "./AttributeValueEntries";
import { DocumentDetails } from "./DocumentDetails";
import "./DocumentEdit.scss";
import { DocumentInstructions } from "./DocumentInstructions";
import { Notes } from "./Notes";
import { Views } from "./Views";
import { DOCUMENTTEMPLATE_NAMES } from "../../../utilities/Constants";
import { dateFormat } from "../../../utilities/configs/DocumentStatisticsConfig";
import { useNotification } from "../../../hooks/useNotification";
import { useCmsContext } from "../../../context/app/CmsContext";
import {
  useQueryDocumentSerie,
  useSaveDocumentserie,
} from "../../../queries/document-series/detail";
import { useQueryDocumentTemplate } from "../../../queries/document-template/detail";
import { useCreateNotification } from "../../../queries/notifications/detail";
import {
  getDocumentCheckerUrl,
  updateFormStatusWithSection,
  ValidateDocumentAsync,
} from "../../../utilities/helpers/document-helper";
import { NotificationsModal } from "./NotificationsModal";
const { TabPane } = Tabs;

export type notificationModal = {
  modal: boolean;
  send: boolean;
};

export const DocumentEdit = () => {
  const { canUpdate, isAdmin } = useAuthorization("document");
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();
  const { push } = useHistory();
  const { id: documentId } = useParams<Entity>();
  const context = useCmsContext();
  const [notificationModal, setNotificationModal] = useState<notificationModal>({
    modal: false,
    send: false,
  });
  const [dcNotification, setDcNotification] = useState<Notification>({
    action: ActionType.NotSpecified,
    entityId: undefined,
    entityType: EntityType.NotSpecified,
    type: NotificationType.NotSpecified,
  });

  const {
    data: document,
    isLoading: isLoadingDocument,
    refetch: refetchDocument,
  } = useQueryDocumentSerie(documentId);
  const { data: documentTemplate, isLoading: isLoadingDocumentTemplate } = useQueryDocumentTemplate(
    document?.documentTemplateId,
  );
  const { mutateAsync: saveDocument, isLoading: isSavingDocument } = useSaveDocumentserie();
  const { mutateAsync: createNotification, isLoading: isCreatingNotification } =
    useCreateNotification();

  const isLoadingAny =
    isLoadingDocument || isLoadingDocumentTemplate || isSavingDocument || isCreatingNotification;

  const noDataAny = !document || !documentTemplate;

  useEffect(() => {
    if (document?.code) {
      context?.setDuplicateErrorMessage(t("errors:duplicateDocument", { code: document.code }));
      context?.setBreadcrumbItems([{ key: "documents", name: document.code }]);
    }
  }, [document, t]);

  useEffect(() => {
    const getSafeModeSetting = localStorage.getItem("safeEditMode");
    if (getSafeModeSetting != null) {
      context?.setSaveEditMode(isAdmin ? JSON.parse(getSafeModeSetting) === true : true);
    }
  }, [isAdmin]);

  const getSectionValidity = (status: any) => {
    let viewsValid = true;
    for (const key of status.invalidSections.keys()) {
      if (key.indexOf("view") === 0) {
        viewsValid = false;
        break;
      }
    }

    return {
      documentDetailsValid: !status.invalidSections.has("documentDetails"),
      documentAttributesValid: !status.invalidSections.has("documentAttributes"),
      viewsValid,
    };
  };

  const handleNoficationCreation = async () => {
    let entityType;
    switch (documentTemplate?.name) {
      case DOCUMENTTEMPLATE_NAMES.BANKNOTE:
        entityType = EntityType.Banknote;
        break;
      case DOCUMENTTEMPLATE_NAMES.COUNTERFEIT_BANKNOTE:
        entityType = EntityType.CounterfeitBanknote;
        break;
      default:
        entityType = EntityType.Document;
        break;
    }

    await createNotification({
      ...dcNotification,
      entityId: documentId,
      entityType: entityType,
    });

    setNotificationModal({ modal: false, send: false });
    setDcNotification((prevState) => ({
      ...prevState,
      action: ActionType.NotSpecified,
      type: NotificationType.NotSpecified,
    }));
  };

  const changeSafeEditingMode = (enabled: boolean) => {
    context?.setSaveEditMode(enabled);
    localStorage.setItem("safeEditMode", enabled.toString());

    notifySuccess(
      t(
        `safeEditMode:${enabled ? "enabledDescription" : "disabledDescription"}`,
        t(`safeEditMode:${enabled ? "enabled" : "disabled"}`),
      ),
    );
  };

  const setPublishingDate = (values: DocumentSeries): DocumentSeries => {
    if (
      document?.status !== DocumentStatus.ReadyForPublishing &&
      values.status === DocumentStatus.ReadyForPublishing
    ) {
      const attributeLink = documentTemplate?.attributeLinks.find(
        (link) => link.attributeName === "Publishing date",
      );

      const index =
        values.attributeValues?.findIndex(
          (attrValue) => attrValue.attributeLinkId === attributeLink?.id,
        ) ?? -1;

      const value = dayjs(new Date(Date.now()).toLocaleDateString()).format(dateFormat);

      if (values.attributeValues) {
        if (index !== -1) {
          if (values.attributeValues[index].value === "") {
            values.attributeValues[index].value = value;
          }
        } else {
          values.attributeValues.push({
            attributeLinkId: attributeLink?.id,
            value,
          });
        }
      }

      return values;
    }

    return values;
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <>
      <Formik
        initialValues={document ?? ({} as DocumentSeries)}
        initialStatus={{ invalidSections: new Set() }}
        enableReinitialize={true}
        validate={(values) => ValidateDocumentAsync(values, t, document?.code)}
        onSubmit={async (document) => {
          await saveDocument(setPublishingDate(document));
          !document.id
            ? push(`/document-series?documentTemplateId=${document.documentTemplateId}`)
            : await refetchDocument();

          if (notificationModal.send) {
            await handleNoficationCreation();
          }
        }}
      >
        {(formikProps) => {
          const { values, status, dirty, setFieldValue, setStatus, submitForm } = formikProps;

          const { documentDetailsValid, documentAttributesValid, viewsValid } =
            getSectionValidity(status);

          return (
            <React.Fragment>
              <Prompt when={dirty} message={t("common:unsavedChanges")} />
              <CmsPageHeader
                title="Document"
                extra={[
                  <CmsBackButton
                    key="back"
                    disabled={isLoadingDocument}
                    onClick={() =>
                      push(`/document-series?documentTemplateId=${document?.documentTemplateId}`)
                    }
                  />,
                  isAdmin && document?.status === DocumentStatus.Preview && (
                    <Button
                      type="default"
                      key="new"
                      icon={<EyeOutlined />}
                      disabled={isLoadingDocument}
                      onClick={() =>
                        window.open(
                          `${config.dcBaseUrl}/${getDocumentCheckerUrl(
                            documentTemplate?.name,
                          )}/${document?.id}`,
                        )
                      }
                    />
                  ),
                  isAdmin && (
                    <CmsButton
                      key="saveEditMode"
                      buttonType="default"
                      confirmText={
                        context?.saveEditMode ? t("safeEditMode:disableDescription") : undefined
                      }
                      disabled={isLoadingDocument}
                      onClick={() => changeSafeEditingMode(!context?.saveEditMode)}
                      className={classNames({
                        safeModeActivated: context?.saveEditMode,
                        safeModeDisabled: !context?.saveEditMode,
                      })}
                      title={t("safeEditMode:description")}
                    >
                      {context?.saveEditMode ? (
                        <LockOutlined />
                      ) : (
                        <UnlockOutlined className="blinker" />
                      )}
                    </CmsButton>
                  ),
                  canUpdate && (
                    <>
                      <Button
                        key="addNotification"
                        disabled={isLoadingDocument || status.invalidSections.size > 0}
                        type={notificationModal.send ? "primary" : "dashed"}
                        icon={
                          notificationModal.send ? <CheckCircleOutlined /> : <PlusCircleOutlined />
                        }
                        loading={isLoadingAny}
                        onClick={() =>
                          setNotificationModal((prevState) => ({
                            ...prevState,
                            modal: true,
                          }))
                        }
                      >
                        {t(
                          `common:${
                            notificationModal.send ? "addedNotification" : "addNotification"
                          }`,
                        )}
                      </Button>
                      <CmsSaveButton
                        key="save"
                        disabled={isLoadingDocument || status.invalidSections.size > 0 || !dirty}
                        loading={isLoadingDocument}
                        onClick={async () => await submitForm()}
                      />
                    </>
                  ),
                ]}
              />
              <CmsTabs destroyInactiveTabPane={true}>
                <TabPane
                  key="documentDetails"
                  tab={
                    <span
                      className={
                        !documentDetailsValid || !documentAttributesValid ? "error" : undefined
                      }
                    >
                      {t("common:details")}{" "}
                      {!documentDetailsValid || !documentAttributesValid ? (
                        <WarningOutlined />
                      ) : null}
                    </span>
                  }
                >
                  <DocumentDetails />
                  {documentTemplate?.attributeLinks && values.attributeValues && (
                    <AttributeValueEntries
                      attributeLinks={documentTemplate.attributeLinks}
                      attributeValues={values.attributeValues}
                      onChange={(attributeValues) =>
                        setFieldValue("attributeValues", attributeValues)
                      }
                      onValidityChanged={(isValid) =>
                        setStatus(
                          updateFormStatusWithSection(status, "documentAttributes", isValid),
                        )
                      }
                    />
                  )}
                </TabPane>
                <TabPane tab={t("common:instructions")} key="texts" disabled={!canUpdate}>
                  <DocumentInstructions documentTemplateType={documentTemplate.name} />
                </TabPane>
                <TabPane
                  key="views"
                  tab={
                    <span className={!viewsValid ? "error" : undefined}>
                      {t("common:views")} {!viewsValid ? <WarningOutlined /> : null}
                    </span>
                  }
                >
                  <Views viewTemplates={documentTemplate?.viewTemplates || []} />
                </TabPane>
                <TabPane tab={t("common:notesBanknotes")} key="notes">
                  <Notes />
                </TabPane>
              </CmsTabs>
            </React.Fragment>
          );
        }}
      </Formik>
      {canUpdate && notificationModal.modal && (
        <NotificationsModal
          document={document}
          notification={dcNotification}
          onCancel={() => {
            setNotificationModal(() => ({
              modal: false,
              send: false,
            }));
            setDcNotification((prevState) => ({
              ...prevState,
              type: NotificationType.NotSpecified,
              action: ActionType.NotSpecified,
            }));
          }}
          onChange={setDcNotification}
          onSave={() =>
            setNotificationModal(() => ({
              modal: false,
              send: true,
            }))
          }
          onSubmit={async () => {
            await handleNoficationCreation();
            setNotificationModal(() => ({
              modal: false,
              send: false,
            }));
          }}
        />
      )}
    </>
  );
};
