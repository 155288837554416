import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Currency } from "../../../models/reference_lists/Currency";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm, CmsNumber, CmsText } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { ValidateCurrency } from "../../../utilities/helpers/currency-helper";
import {
  useQueryCurrency,
  useSaveCurrency,
} from "../../../queries/currencies/detail";
import { useCmsContext } from "../../../context/app/CmsContext";

export const CurrencyDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("currency");
  const { push } = useHistory();
  const { id: currencyId } = useParams<Entity>();
  const context = useCmsContext();

  const {
    data: currency,
    isLoading: isLoadingCurrency,
    refetch: refetchCurrency,
  } = useQueryCurrency(currencyId);
  const { mutateAsync: saveCurrency, isLoading: isSavingCurrency } =
    useSaveCurrency();

  useEffect(() => {
    if (currency) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateCurrency", {
          name: currency.name,
          isoCode: currency.isoCode,
        })
      );

      context?.setBreadcrumbItems([
        {
          key: "currency",
          name: currency.name ?? t("common:new"),
        },
      ]);
    }
  }, [currency, t]);

  const isLoadingAny = (isLoadingCurrency && !!currencyId) || isSavingCurrency;
  const noDataAny = !currency && !!currencyId;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={currency ?? new Currency()}
      enableReinitialize={true}
      validate={(currency: Currency) => ValidateCurrency(currency, t)}
      onSubmit={async (currency: Currency) => {
        await saveCurrency(currency);
        !currency.id ? push(`/currencies`) : await refetchCurrency();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title={t("entities:currency")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/currencies`)}
                />,
                !!canUpdate && (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ),
              ]}
            />
            <CmsForm>
              <CmsText
                id="name"
                label={t("properties:name")}
                placeholder={t("properties:name")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={50}
                error={errors.name}
                value={values.name}
                onChange={handleChange}
              />

              <CmsText
                id="isoCode"
                label={t("properties:isoCode3")}
                placeholder={t("properties:isoCode3")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={3}
                error={errors.isoCode}
                value={values.isoCode}
                onChange={handleChange}
              />

              <CmsText
                id="isoNumber"
                label={t("properties:isoNumber3")}
                placeholder={t("properties:isoNumber3")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={3}
                error={errors.isoNumber}
                value={values.isoNumber}
                onChange={handleChange}
              />

              <CmsNumber
                id="exponent"
                label={t("properties:exponent")}
                placeholder={t("properties:exponent")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                precision={0}
                value={values.exponent}
                onChange={(value) => setFieldValue("exponent", value)}
              />

              <CmsText
                id="subunit"
                label={t("properties:subunit")}
                placeholder={t("properties:subunit")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={50}
                value={values.subunit}
                onChange={handleChange}
              />

              <CmsText
                id="symbol"
                label={t("properties:symbol")}
                placeholder={t("properties:symbol")}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={50}
                value={values.symbol}
                onChange={handleChange}
              />
            </CmsForm>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
