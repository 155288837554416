import { Transfer } from "antd";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Region } from "../../../models/reference_lists/Region";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import {
  CmsForm,
  CmsFormItem,
  CmsMultilingualText,
  CmsText,
} from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryCurrencies } from "../../../queries/currencies/lists";
import { useQueryRegion, useSaveRegion } from "../../../queries/regions/detail";
import { useQueryCountries } from "../../../queries/countries/lists";
import { ValidateRegion } from "../../../utilities/helpers/region-helper";
import { useCmsContext } from "../../../context/app/CmsContext";

export const RegionDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("region");
  const { push } = useHistory();
  const { id: regionId } = useParams<Entity>();
  const context = useCmsContext();

  const {
    data: region,
    isLoading: isLoadingRegion,
    refetch: refetchRegion,
  } = useQueryRegion(regionId);
  const { mutateAsync: saveRegion, isLoading: isSavingRegion } =
    useSaveRegion();
  const { data: currencies, isLoading: isLoadingCurrencies } =
    useQueryCurrencies();
  const { data: countries, isLoading: isLoadingCountries } =
    useQueryCountries();

  React.useEffect(() => {
    if (region) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateRegion", { isoCode: region.isoCode })
      );

      context?.setBreadcrumbItems([
        {
          key: "region",
          name: getLocalizedValue(region.name) ?? t("common:new"),
        },
      ]);
    }
  }, [region, t]);

  const isLoadingAny =
    (isLoadingRegion && !!regionId) ||
    isLoadingCountries ||
    isLoadingCurrencies ||
    isSavingRegion;
  const noDataAny = (!region && !!regionId) || !countries || !currencies;

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Formik
      initialValues={region ?? new Region()}
      enableReinitialize={true}
      validate={(region: Region) => ValidateRegion(region, t)}
      onSubmit={async (region: Region) => {
        await saveRegion(region);
        !region.id ? push(`/regions`) : await refetchRegion();
      }}
    >
      {(formikProps) => {
        const {
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
          errors,
          isValid,
          dirty,
        } = formikProps;

        return (
          <React.Fragment>
            <Prompt when={dirty} message={t("common:unsavedChanges")} />
            <CmsPageHeader
              title={t("entities:region")}
              extra={[
                <CmsBackButton
                  key="back"
                  disabled={isLoadingAny}
                  onClick={() => push(`/regions`)}
                />,
                !!canUpdate && (
                  <CmsSaveButton
                    key="save"
                    disabled={!isValid || isLoadingAny}
                    loading={isLoadingAny}
                    onClick={() => handleSubmit()}
                  />
                ),
              ]}
            />
            <CmsForm>
              <CmsMultilingualText
                label={t("properties:name")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                error={errors.name}
                value={values.name}
                onChange={(value) => setFieldValue("name", value)}
              />
              <CmsText
                id="isoCode"
                label={t("properties:isoCode")}
                placeholder={t("properties:isoCode")}
                required={true}
                disabled={isLoadingAny}
                readOnly={!canUpdate}
                maxLength={50}
                error={errors.isoCode}
                value={values.isoCode}
                onChange={handleChange}
              />

              <CmsFormItem label={t("entities:countries")}>
                <Transfer
                  dataSource={countries.map((country) => ({
                    key: country.id,
                    title: getLocalizedValue(country.name),
                  }))}
                  disabled={!canUpdate || isLoadingAny}
                  targetKeys={values.countryIds}
                  onChange={(value) => {
                    setFieldValue("countryIds", value);
                  }}
                  render={(item) => item.title as string}
                />
              </CmsFormItem>

              <CmsFormItem label={t("entities:currencies")}>
                <Transfer
                  dataSource={currencies.map((currency) => ({
                    key: currency.id,
                    title: currency.name,
                  }))}
                  disabled={!canUpdate || isLoadingAny}
                  targetKeys={values.currencyIds}
                  onChange={(value) => {
                    setFieldValue("currencyIds", value);
                  }}
                  render={(item) => item.title as string}
                />
              </CmsFormItem>
            </CmsForm>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};
