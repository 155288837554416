import {
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Card, Checkbox, Modal, Popconfirm, Spin, Tabs } from "antd";
import { useFormik } from "formik";
import update from "immutability-helper";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../../Config";
import { useApi } from "../../../hooks/useApi";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Image } from "../../../models/data/Image";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { SecurityFeatureSelectionImage } from "../../../models/data/SecurityFeatureSelectionImage";
import { SecurityFeature } from "../../../models/templates/SecurityFeature";
import { CmsButton, CmsUploadButton } from "../../common/ButtonComponents";
import { selectLetterByIndex } from "../../../utilities/AlfabetHelper";
import { CmsForm, CmsFormItem } from "../../common/FormComponents";
import { HologramImage } from "../../common/Hologram";
import { CmsTabs } from "../../common/PageComponents";
import { AttributeValueEntries } from "./AttributeValueEntries";
import { InstructionsList } from "./InstructionsList";
import "./SecurityFeatureDetailsModal.scss";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import SecurityFeatureInstructionCopyModal from "./SecurityFeatureInstructionCopyModal";
import {
  generateSecurityFeatureSelectionPlaceholder,
  rearrangeOrder,
} from "../../../utilities/SecurityFeatureSelectionHelper";
import { RcFile } from "antd/lib/upload/interface";

interface Props {
  image: Image;
  securityFeature: SecurityFeature;
  securityFeatureSelection: SecurityFeatureSelection;
  onSave: (securityFeatureSelection: SecurityFeatureSelection) => void;
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  copyModal: boolean;
}

export const SecurityFeatureDetailsModal = (props: Props) => {
  const { canUpdate } = useAuthorization("document");

  const { t } = useTranslation();

  const [state, setState] = useState<State>({
    isLoading: false,
    copyModal: false,
  });

  const { post: saveFile } = useApi<File>("file");
  const { post: saveHologram } = useApi<File>("hologram");

  const formik = useFormik({
    initialValues: props.securityFeatureSelection,
    onSubmit: (securityFeatureSelection) => {
      props.onSave(securityFeatureSelection);
    },
    enableReinitialize: true,
  });

  const uploadImage = async (
    file: string | Blob | RcFile,
    index?: number,
    securityFeatureSelectionImage?: SecurityFeatureSelectionImage
  ) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const response = await saveFile(file, { postAsFile: true });
    if (response.isSuccess) {
      if (securityFeatureSelectionImage) {
        const imageIndex =
          index ??
          formik.values.images?.findIndex(
            (image) => image.id === securityFeatureSelectionImage.id
          );

        const images = formik.values.images;

        if (!!images && images.length > 0 && imageIndex !== undefined) {
          images[imageIndex] = {
            ...securityFeatureSelectionImage,
            fileId: response.data.id,
          } as SecurityFeatureSelectionImage;

          formik.setFieldValue("images", images);
        }
      } else {
        const image = {
          fileId: response.data.id,
        } as SecurityFeatureSelectionImage;
        const images = update(formik.values.images || [], { $push: [image] });
        formik.setFieldValue("images", rearrangeOrder(images));
      }
    }

    setState((prevState) => ({ ...prevState, isLoading: false }));
  };

  const uploadHologramImage = async (file: string | Blob | RcFile) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const response = await saveHologram(file, { postAsFile: true });
    if (response.isSuccess) {
      formik.setFieldValue("hologramImageId", response.data.id);
    }

    setState((prevState) => ({ ...prevState, isLoading: false }));
  };

  const deleteImage = (index: number) => {
    const images = update(formik.values.images, { $splice: [[index, 1]] });

    if (!images || images.length === 0) {
      return;
    }

    formik.setFieldValue("images", rearrangeOrder(images));
  };

  const deleteHologramImage = () => {
    formik.setFieldValue("hologramImageId", null);
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const formIsValid =
    formik.isValid && (!formik.status || formik.status.attributeValuesValid);

  return (
    <Modal
      width={1000}
      title={`${getLocalizedValue(props.securityFeature.caption)} ${
        props.securityFeatureSelection.order + 1
      } ${t("common:details")}`}
      open={true}
      closable={true}
      onCancel={props.onClose}
      footer={[
        <CmsButton buttonType="cancel" onClick={props.onClose} />,
        canUpdate && (
          <CmsButton
            buttonType="save"
            key="save"
            disabled={!formIsValid}
            onClick={formik.submitForm}
          />
        ),
      ]}
    >
      <>
        <CmsTabs
          className={`tabs ${
            formik.values.images && formik.values.images.length > 2
              ? "increased-height"
              : ""
          }`}
        >
          <Tabs.TabPane tab={t("common:details")} key="details">
            <div className="tab-content tab-details">
              <div
                className={`security-feature-images ${
                  formik.values.images && formik.values.images.length > 4
                    ? "scroll-mode"
                    : ""
                }`}
              >
                <Spin
                  spinning={state.isLoading}
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, marginBottom: 20 }}
                      spin
                    />
                  }
                  tip={t("common:uploading") + "..."}
                >
                  {canUpdate && (
                    <>
                      <CmsButton
                        buttonType="add"
                        onClick={() =>
                          formik.setFieldValue(
                            "images",
                            generateSecurityFeatureSelectionPlaceholder(
                              formik.values.images ?? []
                            )
                          )
                        }
                      >
                        {t("common:generatePlaceholder")}
                      </CmsButton>
                      <CmsUploadButton
                        accept="image/*"
                        customRequest={(options) => uploadImage(options.file)}
                      />
                    </>
                  )}
                  <div className="security-feature-image-list">
                    {formik.values.images && formik.values.images.length > 0 ? (
                      formik.values.images.map((image, index) => {
                        return (
                          <Card
                            key={image.id || index}
                            actions={[
                              canUpdate && (
                                <CmsUploadButton
                                  accept="image/*"
                                  customRequest={(options) =>
                                    uploadImage(options.file, index, image)
                                  }
                                >
                                  <UploadOutlined
                                    key="replaceImage"
                                    title={t("common:replaceImage")}
                                  />
                                </CmsUploadButton>
                              ),
                              canUpdate && (
                                <Popconfirm
                                  title={t("common:confirmDelete")}
                                  onConfirm={() => deleteImage(index)}
                                  okText={t("common:yes")}
                                  cancelText={t("common:no")}
                                >
                                  <DeleteOutlined
                                    key="delete"
                                    title={t("common:delete")}
                                  />
                                </Popconfirm>
                              ),
                            ]}
                            title={selectLetterByIndex(index)}
                          >
                            <div className="security-feature-image-container">
                              <img
                                src={
                                  !image.fileId
                                    ? "/assets/images/keesing-logo.jpg"
                                    : `${config.apiUrl}file/${image.fileId}?imageSize=L`
                                }
                                alt={t("entities:securityFeature")}
                              />
                            </div>
                          </Card>
                        );
                      })
                    ) : props.securityFeatureSelection.id != null ? (
                      <Card>
                        <div className="security-feature-image-container">
                          <img
                            src={`${config.apiUrl}file/${props.image.fileId}?selectionId=${props.securityFeatureSelection.id}&imageSize=L`}
                            alt={t("entities:securityFeature")}
                          />
                        </div>
                        <div className="ant-card-actions">&nbsp;</div>
                      </Card>
                    ) : (
                      <div className="dummy-image">&nbsp;</div>
                    )}
                  </div>
                </Spin>
              </div>
              <div className="security-feature-details">
                <b>Details</b>
                <CmsForm {...formLayout}>
                  <CmsFormItem
                    label={t("properties:exportable")}
                    labelAlign="left"
                  >
                    <Checkbox
                      disabled={!canUpdate}
                      checked={formik.values.exportable}
                      onChange={(e) =>
                        formik.setFieldValue("exportable", e.target.checked)
                      }
                    />
                  </CmsFormItem>
                </CmsForm>
                {props.securityFeature.attributeLinks.length > 0 ? (
                  <React.Fragment>
                    <b>Attributes</b>
                    <AttributeValueEntries
                      attributeLinks={props.securityFeature.attributeLinks}
                      attributeValues={formik.values.attributeValues ?? []}
                      formLayout={formLayout}
                      colLayout={{ span: 24 }}
                      labelAlign="left"
                      renderMode="onecolumn"
                      onChange={(attributeValues) => {
                        formik.setFieldValue(
                          "attributeValues",
                          attributeValues
                        );
                      }}
                      onValidityChanged={(isValid) => {
                        formik.setStatus({ attributeValuesValid: isValid });
                      }}
                    />
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("entities:hologram")} key="hologram">
            <div className="tab-content tab-hologram">
              {canUpdate && (
                <CmsUploadButton
                  accept="application/zip"
                  customRequest={(options) => uploadHologramImage(options.file)}
                />
              )}
              {formik.values.hologramImageId ? (
                <Card
                  actions={[
                    <Popconfirm
                      title={t("common:confirmDelete")}
                      onConfirm={() => deleteHologramImage()}
                      okText={t("common:yes")}
                      cancelText={t("common:no")}
                    >
                      <DeleteOutlined key="delete" title={t("common:delete")} />
                    </Popconfirm>,
                  ]}
                >
                  <HologramImage fileId={formik.values.hologramImageId} />
                </Card>
              ) : (
                <div className="dummy-image">
                  <img
                    src={"assets/images/keesing-icon-transparant-small.png"}
                    alt={"keesing-dummy"}
                  />
                </div>
              )}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t("common:instructions")}
            key="instructions"
            disabled={!canUpdate}
          >
            <div className="tab-content">
              {canUpdate && (
                <CmsButton
                  buttonType="copy"
                  style={{ marginBottom: "12px" }}
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, copyModal: true }))
                  }
                >
                  {t("common:copyInstructions")}
                </CmsButton>
              )}
              <InstructionsList
                instructionsList={formik.values.instructions}
                onChange={(instructions) => {
                  formik.setFieldValue("instructions", instructions);
                }}
              />

              {state.copyModal && (
                <SecurityFeatureInstructionCopyModal
                  key={"securityFeatureInstructionCopyModal"}
                  securityFeatureSelection={props.securityFeatureSelection}
                  viewId={props.image.viewId ?? ""}
                  onClose={(instructions) => {
                    if (instructions) {
                      formik.setFieldValue("instructions", instructions);
                    }

                    setState((prevState) => ({
                      ...prevState,
                      copyModal: false,
                    }));
                  }}
                />
              )}
            </div>
          </Tabs.TabPane>
        </CmsTabs>
      </>
    </Modal>
  );
};
