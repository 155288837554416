import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import ImageDimensions from "../../../../models/data/TargetImageDimensions";

export default function RectactleCrop(props: {
  src: string;
  t: TFunction;
  onCroppedImage: (x: any) => void;
  onImageSize: (x: any) => void;
  onSelection: (x: any) => void;
  preSelection: Crop;
}) {
  const [selection, setSelection] = useState<Crop>();
  const [internalSelection, setInternalSelection] = useState<Crop>({
    unit: "%",
    height: (props.preSelection.height ?? 0) * 100,
    width: (props.preSelection.width ?? 0) * 100,
    x: (props.preSelection.x ?? 0) * 100,
    y: (props.preSelection.y ?? 0) * 100,
  });
  const [imageSize, setImageSize] = useState<ImageDimensions>();
  const [croppedImage, setCroppedImage] = useState<string | undefined>();

  // TODO: remove this when new implemenation is done
  const imageSource = `${props.src}&cmsException=true`;

  const handleCroppedImageComplete = () => {
    props.onCroppedImage(croppedImage);
  };
  const handleImageSizeComplete = () => {
    props.onImageSize(imageSize);
  };
  const handleSelectionComplete = () => {
    props.onSelection(selection);
  };

  const exportAsImage = (crop: Crop, sourceImage: string) => {
    try {
      if (!sourceImage) {
        return null;
      }

      const width = crop.width ?? 0;
      const height = crop.height ?? 0;
      const x = crop.x ?? 0;
      const y = crop.y ?? 0;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const image = new Image();
      image.src = sourceImage;
      image.crossOrigin = "anonymous";

      image.onload = () => {
        const scaleX = image.naturalWidth / (imageSize?.width ?? 0);
        const scaleY = image.naturalHeight / (imageSize?.height ?? 0);

        const ctx = canvas.getContext("2d")!;
        ctx.fillStyle = "rgb(200,0,0)";
        ctx.fillRect(0, 0, width, height);

        ctx.drawImage(
          image,
          x * scaleX,
          y * scaleY,
          width * scaleX,
          height * scaleY,
          0,
          0,
          width,
          height
        );

        const base64Image = canvas.toDataURL("image/jpeg");

        setCroppedImage(base64Image);
      };
    } catch (e) {}
  };

  useEffect(() => {
    if (selection && croppedImage && imageSize) {
      handleImageSizeComplete();
      handleSelectionComplete();
      handleCroppedImageComplete();
    }
  }, [selection, croppedImage, imageSize]);

  useEffect(() => {
    if (selection) {
      exportAsImage(selection, imageSource);
    }
  }, [selection]);

  return (
    <ReactCrop
      src={imageSource}
      crop={internalSelection}
      maxWidth={600}
      onImageLoaded={(currentTarget) => {
        setImageSize({
          width: currentTarget.width,
          height: currentTarget.height,
          natWidth: currentTarget.naturalWidth,
          natHeight: currentTarget.naturalHeight,
        });
      }}
      onChange={(crop) => {
        if (!crop || !imageSize) return;
        setInternalSelection(crop);
      }}
      imageStyle={{ maxWidth: "600px" }}
      onComplete={(crop) => {
        if (!crop) return;
        setSelection(crop);
      }}
    />
  );
}
